import React from 'react';

import Moment from 'react-moment';

import { withKeyAndJsonHeaders } from '../../../api';
import { XistComment as XistCommentType } from '../list/XistList';
import { RemoteContent, success } from '../../../utils/Loading';

interface XistCommentProps {
    comment: XistCommentType,
    projectId: number
}

export function XistComment(props: XistCommentProps) {
    //const [t, i18n] = useTranslation();
    const { comment, created, user_name, user_surname, user_email, hidden, marked, deleted } = props.comment;
    let author: string = (user_surname && user_name) ? `${user_surname} ${user_name}` : user_email;

    const onToggle = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        alert("unimplemented");
        const marked = !(props.comment.marked === true);
        const { task_id, id } = props.comment;
        const { projectId } = props;

        fetch(`/api/v1/project/${projectId}/tasks/${task_id}/comment/${id}`, withKeyAndJsonHeaders({
            method: 'PUT',
            body: JSON.stringify({ mark: marked })
        }));
    }

    const toggleHidden = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        alert("unimplemented");
        const { hidden, task_id, id } = props.comment;
        const { projectId } = props;

        fetch(`/api/v1/project/${projectId}/tasks/${task_id}/comment/${id}`, withKeyAndJsonHeaders({
            method: 'PUT',
            body: JSON.stringify({ hidden: !hidden })
        }));
    }

    const onDelete = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        alert("unimplemented");
        const { task_id, id } = props.comment;
        const { projectId } = props;
        const confirmed = confirm("Wollen Sie den Kommentar wirklich löschen?");
        if (confirmed) {
            fetch(`/api/v1/project/${projectId}/tasks/${task_id}/comment/${id}`, withKeyAndJsonHeaders({
                method: 'PUT',
                body: JSON.stringify({ delete: true })
            }));
        }
    }

    const render = () => {
        return (
            <div className="row">
                <div className="col-12">
                    <span className="text-muted">
                        <span className="small float-end">
                            <Moment date={created} format="DD.MM.YYYY HH:mm" /><br />
                            {!deleted && <a className="float-end" href="" onClick={onDelete}><i className="material-icons">delete</i></a>}
                            {!deleted &&
                                <>
                                    {hidden ?
                                        <a className="float-end" href="" onClick={toggleHidden}><i className="material-icons">speaker_notes_off</i></a> :
                                        <a className="float-end" href="" onClick={toggleHidden}><i className="material-icons">speaker_notes</i></a>}
                                    {marked ?
                                        <a className="float-end" href="" onClick={onToggle}><i className="material-icons">star</i></a> :
                                        <a className="float-end" href="" onClick={onToggle}><i className="material-icons">star_border</i></a>}
                                </>
                            }
                        </span>
                        {author}
                    </span>
                    {(!hidden && !deleted) && (
                        <p>
                            <span dangerouslySetInnerHTML={{ __html: comment }}></span>
                        </p>
                    )}
                    {hidden && (
                        <p className="text-muted">Kommentar ausgeblendet.</p>
                    )}
                    {deleted && (
                        <p className="text-muted">Kommentar gelöscht.</p>
                    )}
                </div>
            </div>
        )
    }
    return <RemoteContent<XistCommentType> url={`/api/v1/project/${props.projectId}/tasks/${props.comment.task_id}/comment/${props.comment.id}`} render={render} initial={success(props.comment)} />
}