/**
 * @author Steffen Kittel
 */

import React from "react";

import { DashboardXist, DashboardXistList } from "./DashboardXistList";
import { LogEntry, LogList } from "./log_list/DashboardLogList";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { pending, RemoteContent } from "../utils/Loading";
//import { useTranslation } from 'react-i18next';

interface Dashboard {
    logs: LogEntry[],
    tasks: DashboardXist[],
    todos: DashboardXist[]
}
/*
interface MarkLogRead {
    log_id: number;
}*/

interface DashboardProps {
    onNavigate?: any;
    onClickOpenXist: (xist: DashboardXist) => void;
    projectId?: number;
}

export default function Dashboard(props: DashboardProps) {
    //const [t, i18n] = useTranslation();

    const load = () => {
        console.debug("need to implement!")
    }

    function render(data: any, dispatch: any) {
        console.debug("rendering dashboard with", data)
        const onClickRead = (log_id: number) => {
            // ToDo: Implement!!! setLogs({ state: 'loading', data: { log_id: log_id } });
            console.debug("should mark ", log_id, " as read");
            dispatch(pending());
        }
        let { logs, todos, tasks: current } = data;
        if (props.projectId && logs && todos && current) {
            logs = logs.filter((item: LogEntry) => item.project_id == props.projectId,);
            todos = todos.filter((item: any) => item.project_id == props.projectId,);
            current = current.filter((item: any) => item.project_id == props.projectId,);
        }

        return (
            <Row className="row mh-100 overflow-auto">
                <Col>
                    <Row>
                        <LogList
                            onClickRead={onClickRead}
                            logs={logs}
                            reload={load}
                            onNavigate={props.onNavigate}
                            onClickOpenXist={props.onClickOpenXist}
                            tooltip={
                                'Hier werden alle Aktivitäten deiner Projektbeteiligten projektübergreifend angezeigt. Klicke auf "gelesen", damit diese Anzeige wieder verschwindet.'
                            }
                        />);
                    </Row>
                    <Row>
                        <DashboardXistList
                            data={todos}
                            caption={"Meine Arbeitspakete"}
                            reload={load}
                            tooltip={
                                "Hier werden projektübergreifend alle Arbeitspakete angezeigt, für die du als Verantwortlicher zugewiesen wurdest."
                            }
                            projectId={props.projectId}
                            {...props}
                        />);
                    </Row>
                    <Row>
                        <DashboardXistList
                            data={current}
                            caption={"Alle Arbeitspakete"}
                            reload={load}
                            tooltip={
                                "Hier werden alle Arbeitspakete projektübergreifend angezeigt, die als nächstes fällig sind, unabhängig davon wer als Verantwortlicher zugwiesen ist."
                            }
                            projectId={props.projectId}
                            {...props}
                        />);
                    </Row>
                </Col>
            </Row>);
    }

    return (
        <RemoteContent<any> url="/api/v1/dashboard" render={render} />
    );
}