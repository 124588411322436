import React, { useEffect, useReducer } from "react";
import { connection, withKeyAndJsonHeaders } from "../api";
import { BinaryReader } from "@bufbuild/protobuf/wire";

export function LoadingSpinner() {
    return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}

export enum LoadingState {
    LOADING,
    ERROR,
    SUCCESS
}

interface LoadingProps {
    state: LoadingState;
    children?: React.ReactNode;
}

export function Loading(props: LoadingProps) {
    switch (props.state) {
        case LoadingState.LOADING:
            return <LoadingSpinner />
        case LoadingState.ERROR:
            return (
                <>
                    Error loading data
                </>
            );
        case LoadingState.SUCCESS:
            return (
                <>
                    {props.children}
                </>)
    }
}

export interface TypedInitial<Result> {
    state: "initial";
    data: Result;
}

export function initial<Result>(data: Result): TypedInitial<Result> {
    return {
        state: "initial",
        data
    }
}

export interface TypedSuccessful<Result> {
    state: "success";
    data: Result;
}

export function success<Result>(data: Result): TypedSuccessful<Result> {
    return {
        state: "success",
        data
    }
}

export interface TypedPending {
    state: "pending";
    message?: string;
}

export function pending(): TypedPending {
    return {
        state: "pending"
    }
}

export interface TypedLoading<Result> {
    state: "loading";
    message?: string;
    data: Result;
    body?: any;
}

export function loading_(data: any) {
    return { state: "loading", body: data };
}

export interface TypedUpdate<Result> {
    state: "update";
    message?: string;
    data: Result;
}

export function update<Result>(body?: any): TypedUpdate<Result> {
    return { state: "update", data: body }
}

export interface TypedError<Result, Error> {
    state: "error",
    data?: Result,
    error: Error
}

export function error<Error, Result>(error: Error, data?: Result): TypedError<Result, Error> {
    return { state: "error", error: error, data: data }
}

export interface TypedCreate<Result> {
    state: "create",
    data?: Result,
}

export function create<Result>(data: Result): TypedCreate<Result> {
    return { state: "create", data: data }
}

export type TypedLoadingState<Result, Error, Saving = Result> =
    TypedSuccessful<Result> |
    TypedPending |
    TypedLoading<Saving> |
    TypedError<Result, Error> |
    TypedInitial<Result> |
    TypedCreate<Result> |
    TypedUpdate<Result>;

export function hasState<Result, Error, Saving = Result>(state: TypedLoadingState<Result, Error, Saving>): boolean {
    switch (state.state) {
        case "initial":
        case "success":
            return true;
    }
    return false;
}

export interface TypedLoadingProps<Result, Error, Saving> {
    render: (r: Result, e?: Error) => React.ReactNode;
    renderInitial?: (r?: Result) => React.ReactNode;
    error?: (e: Error) => React.ReactNode;
    state: TypedLoadingState<Result, Error, Saving>;
}

export function TypedLoading<Result, Error, Saving = Result>(props: TypedLoadingProps<Result, Error, Saving>) {
    switch (props.state.state) {
        case "create":
            return (
                <div>
                    Creating...
                </div>
            )
        case "pending":
        case "update":
        case "loading":
            return (
                <>
                    <div className="text-center">
                        <LoadingSpinner />
                        Laden...
                        <div>{props.state.message && (<>Status: {props.state.message}</>)}</div>
                    </div>
                </>
            );
        case "error":
            return (
                <>
                    Fehler beim Laden der Daten
                    {props.error?.(props.state.error)}
                </>
            );
        case 'initial':
            return (
                <>
                    {props.state.data && props.render(props.state.data)}
                </>
            )
        case "success":
            return (
                <>
                    {props.render(props.state.data)}
                </>)
    }
}

type Dispatch<Result, _Action> = (prev: TypedLoadingState<Result, string>, action: any) => TypedLoadingState<Result, string>;

export interface Subscription<Result> {
    event: string;
    callback?: (e: any) => TypedLoadingState<Result, Error> | null;
}

export interface useRemoteContentProps<Result, Action = any, DataAction = any> {
    url: string;
    render: (result: Result, dispatch: (action: Action) => void, dispatchData?: (action: DataAction) => void) => React.ReactNode; // Dispatch only gets the action!
    initial?: TypedLoadingState<Result, string>;
    reducer?: Dispatch<Result, Action>;
    subscribe?: Subscription<Result>;
    createCallback?: (responseBody: any) => Result;
    decode?: (input: BinaryReader | Uint8Array, length?: number) => Result;
    encode?: (input: Result) => any;
}

function defaultReducer<Result, Error>(_prev: TypedLoadingState<Result, Error>, _action: any) {
    return _action;
}

/** This function requires that the object already exists! */
export function RemoteContent<Result, Action = any>(props: useRemoteContentProps<Result, Action>) {
    const reducer = props.reducer ?? defaultReducer;
    const initial = props.initial ?? pending();
    const [state, dispatch]: [TypedLoadingState<Result, string, Result>, React.Dispatch<any>] = useReducer(reducer, initial); // any is the action, should be typed to {action: bla, value: blubb}
    useEffect(() => {
        if (props.subscribe) {
            const event = props.subscribe.event;
            const cb = props.subscribe.callback
            const callback = (value: any) => {
                let next: TypedLoadingState<Result, Error> = pending();
                if (cb) {
                    let v = cb(value);
                    if (v !== null) {
                        next = v;
                    }
                }
                setTimeout(() => { dispatch(next); });
            };
            connection.on(event, callback)

            return () => {
                connection.off(event, callback);
            }
        }
        return () => { }
    });
    useEffect(() => {
        const abortController = new AbortController();
        const async_helper = async () => {
            const load = async (method: string, da: any, decode?: any) => {
                try {
                    let opts: any = { method, signal: abortController.signal };
                    if (method !== "GET") {
                        opts['body'] = da;
                    }
                    // ToDo: Should switch to withProtobufAnd...
                    const response = await fetch(props.url, withKeyAndJsonHeaders(opts))
                    if (response.status != 200) {
                        throw Error(response.status + " " + response.statusText);
                    }
                    const contentType = response.headers.get('content-type');
                    let data = null;
                    if (contentType === "application/octet-stream") {
                        if (decode !== undefined) {
                            data = decode(response.arrayBuffer());
                        }
                    }
                    else {
                        data = await response.json();
                    }
                    return data;
                } catch (err: any) {
                    if (err.name === 'AbortError') {
                        return null;
                    }
                    throw err;
                }
            }
            let next: TypedLoadingState<Result, string, Result> = state;
            switch (state.state) {
                case "pending":
                    const data = await load("GET", null);
                    /**
                     * Should do better error handling. null is not necessarily an error, as it'll represent an aborted request
                     */
                    if (data !== null) {
                        let nxt = success(data);
                        dispatch(nxt);
                    }
                    break;
                case "success":
                    break;
                case "loading":
                case "update":
                    const encode = props.encode ?? JSON.stringify;
                    console.debug("executing update with data ", encode(state.data))
                    const result = await load('PUT', encode(state.data));
                    if (result) {
                        dispatch(success(result));
                        break;
                    }
                    dispatch(error("not working", JSON.stringify(result)))
                    break;
                case "error":
                    break;
                case "initial":
                    break;
                case "create":
                    let result_ = await load('POST', state.data, props.createCallback)
                    if (props.createCallback) {
                        const model = props.createCallback(result_);
                        if (model) {
                            next = success(model);
                        } else {
                            next = error("Error in callback!");
                        }
                    } else {
                        next = pending();
                    }
                    dispatch(next)
                    break;
            }
        }
        async_helper();
        return () => {
            abortController.abort();
        }
    }, [state]);

    switch (state.state) {
        case "pending":
            return (
                <>
                    <div className="text-center">
                        <LoadingSpinner />
                        Laden...
                    </div>
                </>
            )
        case "loading":
            return (
                <>
                    <div className="text-center">
                        <LoadingSpinner />
                        Laden...
                        Nachricht: {state.message}
                    </div>
                </>
            );
        case "error":
            return (
                <>
                    {state.error + state.data}
                </>
            );
        case "initial":
        case "success":
            return (
                <>
                    {props.render(state.data, dispatch)}
                </>
            );
        default:
            return (
                <>{state.state}</>
            )

    }
}