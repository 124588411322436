import React from "react";
import { Link } from 'react-router-dom';
import { TextInput } from "../../ui/forms/TextInput";
import { FormGroupWithLabel } from "../../ui/forms/FormGroupWithLabel";
import { create, initial, RemoteContent, TypedLoadingState } from "../utils/Loading";

const NavbarIconWhite = "navbar_icon_white";

enum UserActionKind {
    SURNAME = 'surname',
    NAME = 'name',
    STREET = 'street',
    ZIP = 'zip',
    CITY = 'city',
    EMAIL = 'email',
    PASSWORD = 'password',
    SUBMIT = 'submit'
}

export interface User {
    surname: string;
    name: string;
    street: string;
    zip: string;
    city: string;
    email: string;
    password: string;
    message: string;
}

interface RegisterUser {
    type: "submit"
}

interface UpdateForm {
    type: "update",
    field: UserActionKind,
    value: string
}

type Message = RegisterUser | UpdateForm

function reducer(prev: TypedLoadingState<User, string, User>, action: Message): TypedLoadingState<User, string, User> {
    switch (action.type) {
        case "update":
            if (prev.state !== "initial") {
                break;
            }
            const { field, value } = action;
            let data = { ...prev.data };
            switch (field) {
                case UserActionKind.SURNAME:
                    data = { ...data, 'surname': value };
                    break;
                case UserActionKind.NAME:
                    data = { ...data, 'name': value };
                    break;
                case UserActionKind.STREET:
                    data = { ...data, 'street': value };
                    break
                case UserActionKind.ZIP:
                    data = { ...data, 'zip': value };
                    break;
                case UserActionKind.CITY:
                    data = { ...data, 'city': value };
                    break;
                case UserActionKind.EMAIL:
                    data = { ...data, 'email': value };
                    break;
                case UserActionKind.PASSWORD:
                    data = { ...data, 'password': value };
                    break;
                default:
                    return prev;
            }
            return initial(data);
        case "submit":
            if (prev.state === "initial") {
                return create(prev.data);
            }
            return prev;
        default:
            // should return error!
            return prev;
    }
    // should return error!
    return prev;
}


export function Register() {
    const user = { surname: '', name: '', street: '', zip: '', city: '', email: '', password: '', message: '' };
    /*
        const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetch(`/api/v1/user`, {
            method: 'POST',
            headers: {
                "Content-Type": 'application/json; utf-8'
            },
            body: JSON.stringify({ ...user })
        }).then(
            async response => {
                const result = await response.json();
                if (result.error === "exists") {
                    // need to update the state an set the message
                    //setMessage("Benutzer existiert bereits.");
                } else {
                    const navigate = useNavigate();
                    //history.push('/registration-successful');
                    navigate('/registration-successful')
                }
            }
        )
    }
    */
    return <RemoteContent<User, Message> url="/api/v1/user" initial={initial(user)} render={render} reducer={reducer} />
}


const render = (model: User, dispatch: (action: Message) => void) => {
    const update = (action: UserActionKind) => {
        return (value: any) => {
            dispatch({ type: "update", field: action, value: value });
        }
    }
    return (
        <form className="form-horizontal" onSubmit={() => dispatch({ type: "submit" })}>
            <div className="modal-dialog shadow-sm" role="document">
                <div className="modal-content border-0">
                    <div className="modal-header">
                        <h2 className="welcome-h4 my-auto">Registrieren bei xistics</h2><img
                            className="pull-right welcome-logo border border-dark" src={NavbarIconWhite}
                            style={{ width: '48px', height: '48px' }} />
                    </div>
                    <div className="modal-body">
                        <FormGroupWithLabel label="Vorname">
                            <TextInput name="surname" value={model.surname} update={update(UserActionKind.SURNAME)} />
                        </FormGroupWithLabel>
                        <FormGroupWithLabel label="Nachname">
                            <TextInput name="name" value={model.name} update={update(UserActionKind.NAME)} />
                        </FormGroupWithLabel>
                        <FormGroupWithLabel label="Strasse">
                            <TextInput name="street" value={model.street} update={update(UserActionKind.STREET)} />
                        </FormGroupWithLabel>
                        <div className="row">
                            <div className="col">
                                <FormGroupWithLabel label="PLZ">
                                    <TextInput name="zip" value={model.zip} update={update(UserActionKind.ZIP)} />
                                </FormGroupWithLabel>
                            </div>
                            <div className="col">
                                <FormGroupWithLabel label="Ort">
                                    <TextInput name="city" value={model.city} update={update(UserActionKind.CITY)} />
                                </FormGroupWithLabel>
                            </div>
                        </div>
                        <FormGroupWithLabel label="E-Mail">
                            <TextInput name="email" value={model.email} update={update(UserActionKind.EMAIL)} />
                        </FormGroupWithLabel>
                        <FormGroupWithLabel label="Passwort">
                            <TextInput name="password" value={model.password} update={update(UserActionKind.PASSWORD)} />
                        </FormGroupWithLabel>
                        {model.message}
                    </div>
                    <div className="modal-footer">
                        <Link to="/" className="btn btn-danger">Abbrechen</Link>
                        <button type="submit" className="btn btn-success">Registrieren</button>
                    </div>
                </div>
            </div>
        </form >
    );
}