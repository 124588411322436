/**
 * @author Steffen Kittel
 */
import React from "react";
import { UserList } from "../administration/UserList";
import { Project } from "../../../protos/protos/projects";

interface TeamTabProps {
    project: Project;
    isProjectLead: boolean;
    readOnly: boolean;
}

export function TeamTab({ project, isProjectLead, readOnly }: TeamTabProps) {
    return (
        <div className="row h-100 overflow-auto">
            <UserList
                project={project}
                isProjectLead={isProjectLead}
                propsPL={isProjectLead}
                readOnly={readOnly}
            />
        </div>
    );
}
