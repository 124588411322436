import React, { useState } from "react";
import { withKeyAndJsonHeaders } from "../../api";

interface CreateAccountGroupProps{
    ledgerId: number;
};
export function CreateAccountGroup(props: CreateAccountGroupProps) {
    console.debug("props are", props)
    const [accountGroup, setAccountGroup] = useState({ kind: 'Assets', name: '' });
    const onSubmit = async (e: any) => {
        e.preventDefault();
        await fetch(`/api/v1/accounting/ledger/${props.ledgerId}/account_groups`, withKeyAndJsonHeaders({
            method: 'POST',
            body: JSON.stringify({
                group_name: accountGroup.name,
                kind: accountGroup.kind
            })
        }));

        console.debug("doing stuff here", accountGroup);
    }
    return (
        <div>
            Kontengruppe erstellen:
            <CreateAccountGroupForm onSubmit={onSubmit} groupName={accountGroup.name} setGroupName={(name: string) => setAccountGroup({ ...accountGroup, name: name })} setKind={(kind: string) => setAccountGroup({ ...accountGroup, kind: kind })} />
        </div>
    )
}

export function CreateAccountGroupForm(props: any) {
    return (
        <form onSubmit={props.onSubmit}>
            <input type="text" value={props.groupName} onChange={(e) => props.setGroupName(e.target.value)} />
            <select onChange={(e) => props.setKind(e.target.value)}>
                <option value="Assets">Aktiva</option>
                <option value="Liabilities">Verbindlichkeiten</option>
                <option value="Equity">Eigenkapital</option>
            </select>
            <input type='submit' className='btn btn-primary btn-sm' value="erstellen" />
        </form>
    )
}