import React, { useEffect, useState } from 'react';
import { withKeyAndJsonHeaders } from '../../../api';
import { Project, Xist } from '../../../../protos/protos/projects';

type onSuccessCallback = () => void;

interface AddCloudDocumentProps {
  project: Project;
  xist: Xist;
  onSuccess: onSuccessCallback;
}

export default function AddCloudDocument({ project, xist, onSuccess }: AddCloudDocumentProps) {
  const [name, setName] = useState<string | null>();
  const [link, setLink] = useState<string | null>();
  const abortController = new AbortController();

  useEffect(() => {
    //$('.modal').modal('show'); ToDo: Fix

    return () => {
      abortController.abort();
    }
  }, []);

  const onSave = () => {
    fetch(`/api/v1/project/${project.id}/tasks/${xist.id}/cloud_documents`, withKeyAndJsonHeaders({
      method: 'POST',
      signal: abortController.signal,
      body: JSON.stringify({ name: name, link: link })
    })).then(() => {
      //$('.modal').modal('hide'); ToDo: Fix
      onSuccess()
    });
  }

  return (
    <div className="modal" data-show="true" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">CloudDocument hinzufügen</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Name:<br />
            <input type="text" name="name" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} /><br />
            Link:<br />
            <input type="text" name="link" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLink(e.target.value)} />
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-primary" onClick={onSave}>Save changes</button>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}