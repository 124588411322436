import React, { useContext } from "react";
import { XistTree } from "./XistTree";
import { XistPanel } from "./XistPanel";
import { ProjectContext } from "../ProjectPresenter";
import { useParams, useSearchParams } from "react-router-dom";
import { VersionId } from "./list/XistList";

interface XistTabProps {
    isProjectLead: boolean;
    readOnly: boolean;
}

export function XistsTab({ isProjectLead, readOnly }: XistTabProps) {
    const project = useContext(ProjectContext);
    const issueId = useParams().xistId;
    const [urlSearchParams, _] = useSearchParams();
    let version: VersionId | null = null;
    const v = urlSearchParams.get("version")
    if (v) {
        version = parseInt(v) as VersionId;
    }
    if (project === null) {
        throw new Error("No project provided!");
    }
    const onClickOpenXist = (_id: number) => {
    }
    return (
        <div className="row h-100" key={issueId} >
            <XistTree className="col-md-3 h-100 d-none d-md-block p-3 text-truncate overflow-auto" projectId={project.id} nodes={project.tasks} />
            <XistPanel key={issueId} version={version} className="col-12 col-md-9 h-100 overflow-auto" project={project} onClickOpenXist={onClickOpenXist} isProjectLead={isProjectLead} readOnly={readOnly} />
        </div>
    );
}