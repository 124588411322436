import React, { useState } from 'react';
import { RemoteContent, success, TypedLoadingState, update } from '../../../utils/Loading';
import { Project } from '../../../../protos/protos/projects';
import { Issue } from '../../../../protos/protos/issues';
import { Link } from 'react-router-dom';

interface ModelCommon {
    xist: Issue;
    projectId: number;
}

interface Edit extends ModelCommon {
    state: "edit";
}

interface View extends ModelCommon {
    state: "view";
}

type Model = Edit | View;

type WrappedModel = TypedLoadingState<Model, string, Model>;
interface XistListItemProps {
    beta: boolean;
    onClickOpenXist_: any;
    projectId: number;
    xist: Issue;
    project: Project
}
type Message = "edit" | "view" | WrappedModel;
export function XistListItem({ beta, onClickOpenXist_, projectId, xist }: XistListItemProps) {
    const onClickOpenXist = (_e: any) => {
        onClickOpenXist_(xist.xist_id);
    }

    return <RemoteContent<Model>
        url={`/api/v1/project/${projectId}/tasks/${xist.xist_id}`}
        initial={success({ state: "view", xist, projectId })}
        reducer={reducer}
        render={(model: Model, dispatch: (action: Message) => void) => {
            const onClickRename = (newName: string) => {
                dispatch(update<Model>({ ...model.xist, name: newName }));
            }
            const onClickDelete = (e: any) => {
                e.preventDefault();
                dispatch(update<Model>({ ...model.xist, "id": xist.xist_id, "status": -1 }));
            }
            switch (model.state) {
                case "edit":
                    return (
                        <XistListItemEdit oldName={model.xist.name} onClickCancel={(e: any) => { e.preventDefault(); dispatch("view"); }} onClickRename={onClickRename} />
                    )
                case 'view':
                    return (
                        <XistListItemView beta={beta} xist={xist} projectId={projectId} onClickEdit={(e: any) => { e.preventDefault(); dispatch("edit"); }} onClickOpenXist={onClickOpenXist} onClickDelete={onClickDelete} />
                    );
                default:
                    return (
                        <>
                            Error! Invalid state {model}
                        </>
                    )
            }
        }} />
}


function reducer(prev: WrappedModel, action: Message): WrappedModel {
    if (typeof action !== "string") {
        return action;
    }
    if (prev.state !== "success") {
        return prev;
    }
    return success({ state: action, xist: prev.data.xist, projectId: prev.data.projectId });
}

interface XistListItemEditProps {
    oldName: string;
    onClickRename: (e: any) => void;
    onClickCancel: (e: any) => void;
}

function XistListItemEdit(props: XistListItemEditProps) {
    const [newName, setNewName] = useState<string>(props.oldName);
    return (
        <div>
            <form>
                <input className="form-control" name="newName" type="text" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewName(e.target.value)} value={newName} />
                <a href="#" className="btn btn-success mb-2" onClick={(e: any) => { e.preventDefault(); props.onClickRename(newName); }}>speichern</a>
                <a href='#' className="btn btn-danger mb-2" onClick={props.onClickCancel}>abbrechen</a>
            </form>
        </div>
    );
}

interface XistListItemViewProps {
    beta: boolean;
    projectId: number;
    xist: Issue;
    onClickOpenXist: (_e: any) => void;
    onClickEdit: (e: any) => void;
    onClickDelete: (e: any) => void;
}

function XistListItemView(props: XistListItemViewProps) {
    return (
        <span className="xist-list-xist">
            {/* TODO: fix xist.show_project_name && <span className={"d-block small text-muted"}>{project.name}</span>*/}
            {props.beta && <button className="btn btn-link border-0 material-icons p-0" onClick={() => {
                props.onClickOpenXist(props.xist)
            }}>
                comment
            </button>}
            <Link to={`/project/${props.projectId}/xist/${props.xist.xist_id}`}>{props.xist.name}</Link>
            <span className='options float-end'>
                <Link to={`/project/${props.projectId}/xist/${props.xist.xist_id}`} target="_blank"><i className='material-icons'>open_in_new</i></Link>
                <a href='#' onClick={props.onClickEdit}><i className='material-icons'>mode_edit</i></a>
                <a href='#' onClick={props.onClickDelete}><i className='material-icons'>delete</i></a>
            </span>
        </span>
    )
}