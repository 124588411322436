import React from 'react';
import { pending, RemoteContent } from '../../utils/Loading';
import { AccountsResponse } from '../../../protos/protos/accounting';
import { CreateAccountGroup } from '../account_groups/CreateAccountGroup';
import { CreateAccount } from './CreateAccount';

interface AccountsProps {
    ledgerId: number
}

export function Accounts(props: AccountsProps) {
    return (
        <>
            <RemoteContent<AccountsResponse | null> url={`/api/v1/accounting/ledger/${props.ledgerId}/accounts`} initial={pending()} render={accountsView} />
        </>
    )
}

function accountsView(data: AccountsResponse | null) {
    if (data) {
        console.debug("data is ", data)
        return (
            <>
                <div>
                    Kontengruppen:
                    <ul>
                        {data.account_groups.map((group) => <li key={group.id}>Art: {group.kind} Name: {group.name}</li>)}
                    </ul>
                    <CreateAccountGroup ledgerId={data.ledger_id} />
                </div>
                <div>
                    Konten:
                    <CreateAccount account_groups={data.account_groups} ledger_id={data.ledger_id} />
                    {data.accounts.map((account) => <span key={account.id}>{account.name}</span>)}
                </div>
            </>
        )
    }
    return (<> Stuff here </>)
}
