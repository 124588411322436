import React, { useState } from 'react';

import classNames from 'classnames';
import { PDFPreview } from './PDFPreview';
import { ImagePreview } from './ImagePreview';
import { Xist, XistFile } from '../../protos/protos/projects';
interface ImageGalleryProps {
    onClose: () => void;
    xist: Xist;
    selected?: XistFile;
    setPreview: (file: XistFile) => void;
};

export function ImageGallery({ onClose, selected, xist, setPreview }: ImageGalleryProps) {
    let preview = selected !== undefined ? selected : xist.files[0];
    const [maximized, setMaximized] = useState<boolean>(false);

    const files = xist.files.map((file: XistFile) => {
        if (file.mime_type.startsWith("image/")) {
            return (
                <div key={file.url} style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '80px',
                        minWidth: '80px'
                    }
                }>
                    <div onClick={(_e: any) => setPreview(file)}
                        style={{
                            width: "100px",
                            height: "50px",
                            backgroundImage: 'url(' + file.url + ')',
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            display: 'block',
                            marginTop: '20px',
                            marginLeft: '5px',
                            marginRight: '5px',
                        }} />
                    <DownloadLink href={file.url} />
                </div>
            )
        } else if (file.mime_type === 'application/pdf') {
            return (
                <div key={file.url} style={
                    {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '80px',
                        minWidth: '80px'
                    }
                }>
                    <a href='#' onClick={(e: any) => { e.preventDefault(); setPreview(file); }}>{file.filename}</a>
                    <DownloadLink href={file.url} />
                </div>
            );
        }
        return (
            <>Irgendwas ist schiefgelaufen</>
        )
    });
    let file = <>"Format nicht unterstützt"</>;
    if (preview.mime_type.startsWith("image/")) {
        file = (<ImagePreview file={preview} />)
    } else if (preview.mime_type === 'application/pdf') {
        file = (
            <PDFPreview file={preview} />
        )
    }

    return (
        <div className={classNames('col-12', { "fullscreen": maximized })}>
            <div id="row">
                <div className='col text-end'>
                    <DownloadLink href={preview.url} />
                    <button type='button' className='btn' onClick={() => setMaximized(!maximized)}><i className='material-icons'>{maximized ? "close_fullscreen" : "open_in_full"}</i></button>
                    <button type="button" className="btn close mr-1" onClick={onClose} aria-label="Close">
                        <i className="material-icons" aria-hidden="true">close</i>
                    </button>
                </div>
            </div>
            <div className='row'>
                {file}
            </div>
            <div className='row'>
                <div className="col d-flex flex-wrap">
                    {files}
                </div>
            </div>
        </div>
    )
}

function DownloadLink({ href }: { href: string }) {
    return (
        <a className="btn" href={href}><i className="material-icons">cloud_download</i></a>
    );
}