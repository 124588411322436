import React, { useEffect, useReducer, useState } from 'react';
import { withKeyAndJsonHeaders } from '../api';


interface PasswordPair {
    password1: string;
    password2: string;
    matches: boolean;
}

enum PasswordKind {
    PASSWORD1 = 'password1',
    PASSWORD2 = 'password2',
    CLEAR = 'clear'
}

interface PasswordAction {
    type: PasswordKind,
    value: string
}

function passwordReducer(state: PasswordPair, action: PasswordAction) {
    const { type, value } = action;
    switch (type) {
        case PasswordKind.PASSWORD1:
            return {
                ...state,
                password1: value,
                matches: state.password2 === value ? true : false
            }
        case PasswordKind.PASSWORD2:
            return {
                ...state,
                password2: value,
                matches: state.password1 === value ? true : false
            }
        case PasswordKind.CLEAR:
            return {
                password1: '',
                password2: '',
                matches: false
            }
        default:
            return state;
    }
}

export interface LoginProfile {
    email: string | undefined;
    id?: number;
}
export interface LoginProps {
    email: string | undefined
}
export function Login({ email }: LoginProps) {
    //const [t, i18n] = useTranslation();
    const [passwordPair, dispatch] = useReducer(passwordReducer, { password1: "", password2: "", matches: true });
    //const [error, setError] = useState(PasswordErrorEnum.NONE);
    const [profile, setProfile] = useState<LoginProfile>({ email: email });
    const abortController = new AbortController();

    useEffect(() => {
        fetch('/api/v1/user', withKeyAndJsonHeaders({ signal: abortController.signal })).then(
            async response => {
                if (response.ok) {
                    const user = await response.json();
                    setProfile(user);
                }
            }
        ).catch(() => { });
        return () => {
            abortController.abort();
        }
    }, []);

    const onSubmit = (e: any) => {
        e.preventDefault();
        const data = {
            id: profile.id,
            password1: passwordPair.password1,
            password2: passwordPair.password2
        };
        fetch(`/api/v1/user/${data.id}`, withKeyAndJsonHeaders({
            method: 'PUT',
            signal: abortController.signal,
            body: JSON.stringify(data)
        })).then(
            () => {
                
                //setPasswordPair({ password1: "", password2: "" }); TODO: Make a clear action!
            }
        );
    }

    return (
        <div className="col-12">
            <form className="credentials-form form-horizontal position-relative" onSubmit={onSubmit}>
                <legend>Zugangsdaten</legend>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-mail">E-Mail</label>
                    <input className="form-control form-control-sm" id="input-mail" type="text" value={profile.email} autoComplete='email' disabled={true}></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-password1">Neues Passwort</label>
                    <input className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordKind.PASSWORD1, value: e.target.value })} type="password"
                        name="password1" value={passwordPair.password1} autoComplete='new-password'></input>
                </div>
                <div className='mb-3'>
                    <label className="form-label" htmlFor="input-password2">Neues Passwort wiederholen</label>
                    <input className="form-control form-control-sm" onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch({ type: PasswordKind.PASSWORD2, value: e.target.value })} type="password"
                        name="password2" value={passwordPair.password2} autoComplete='new-password'></input>
                </div>
                {!passwordPair.matches && (
                    <span>Fehler! Passwörter stimmen nicht überein.</span>
                )}
                {passwordPair.matches && passwordPair.password1 !== "" && (
                    <div className='mb-3'>
                        <input type="submit" className="btn btn-success save form-control" value="&Auml;ndern" />
                    </div>
                )}
            </form>
        </div>
    );
}