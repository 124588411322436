import React, { useState } from 'react';

import { Editor } from '../../../ui/components/Editor';
import { MorphToEditable } from '../../../ui/utils';

interface XistCommentFormProps {
    onSave: (message: string) => void;
}

export function XistCommentForm({ onSave }: XistCommentFormProps) {
    const [message, setMessage] = useState("");

    const onChange = (text: string) => {
        setMessage(text);
    }

    const onSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onSave(message);
        setMessage("");
    }

    return (
        <div className="d-flex flex-row">
            <div className="flex-grow-1 h-100">
                <MorphToEditable
                    plain={(toggle: any) => <div onClick={toggle} style={{ borderBottom: "1px solid grey"}}>&nbsp;</div>}
                    editable={() => <Editor onTextChange={onChange} defaultValue={message} />} />
            </div>
            <button className="btn p-0 mb-0 btn-link align-top" onClick={onSubmit} type="button"
                disabled={message.length == 0}>
                <i className="material-icons" style={{ "color": "#3579F7" }}>send</i>
            </button>
        </div>
    )
}
