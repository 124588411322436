import React, { useState } from "react";
import { RemoteContent } from "../../utils/Loading";
import { withKeyAndJsonHeaders } from "../../api";

export interface TransactionListProps {
    ledgerId: number
}

export interface TransactionEntry {
    id: number,
    transaction_id: number,
    account_id: number,
    amount: number
}

export interface Transaction {
    id: number,
    ledger_id: number,
    timestamp: Date,
    name: string | null
    description: string | null,
    transaction_entries: TransactionEntry[]
}

export function TransactionList(props: TransactionListProps) {
    const url = `/api/v1/accounting/ledger/${props.ledgerId}/transactions`;
    return (
        <RemoteContent<Transaction[]> url={url} render={(transactions: any) => {
            let accounts = new Map();
            for (let account of transactions.accounts) {
                accounts.set(account.id, account);
            }
            return (
                <>
                    {transactions.accounts.length > 0 ? (<NewTransaction accounts={accounts} ledgerId={props.ledgerId} />) : ("Bitte zuerst Konten anlegen!")}
                    <h3>Transaktionen</h3>
                    <div>
                        {transactions.transactions.map((transaction: any) => {
                            console.debug("transaction", transaction)
                            return (
                                <div key={transaction.id} style={{ "margin": "8px" }}>
                                    <u>{transaction.name}</u><br />
                                    {transaction.transaction_entries.map((entry: any) => <div key={entry.id}>{accounts.get(entry.account_id).name}: <span className="float-end">{entry.amount}</span></div>)}
                                </div>
                            )
                        })}
                    </div>
                </>
            )
        }} />
    )
}
interface NewTransactionProps {
    accounts: Map<any, any>,
    ledgerId: number
}
function NewTransaction({ accounts, ledgerId }: NewTransactionProps) {
    const [entries, setEntries] = useState<any>([]);

    const [account_id, setAccountId] = useState(accounts.values().next().value!.id);
    const [amount, setAmount] = useState(0);
    const [name, setName] = useState("");
    let select_options: any[] = [];
    for (let account of accounts.values()) {
        select_options.push(account);
    }
    const add_entry = () => {
        setEntries([...entries, { account_id: account_id, amount: amount }]);
    }
    const book = async () => {
        // "/accounting/ledger/:ledger_id/transactions"
        await fetch(`/api/v1/accounting/ledger/${ledgerId}/transactions`, withKeyAndJsonHeaders({
            method: 'POST',
            body: JSON.stringify({
                name: name,
                transaction_entries: entries
            })
        }));
        window.location.reload();
    }
    console.debug("entries are ", entries)
    return (
        <>
            <h3>Neue Buchung</h3>
            Name: <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} required={true} />
            <table className="table w-100">
                <thead>
                    <tr>
                        <td>Konto</td>
                        <td>Betrag</td>
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <select onChange={(e) => setAccountId(parseInt(e.target.value))} value={account_id}>
                                {select_options.map(account => <option key={account.id} value={account.id}>{account.name}</option>)}
                            </select>
                        </td>
                        <td>
                            <input type="number" min="0.00" step="0.01" name="amount" onChange={(e) => setAmount(parseFloat(e.target.value))} />
                        </td>
                        <td colSpan={2} className="text-end">
                            <input type="submit" value="hinzufügen" onClick={add_entry} />
                        </td>
                    </tr>
                    {entries.map((entry: any) => (
                        <tr>
                            <td>{accounts.get(entry.account_id).name}</td>
                            <td className="text-right">{entry.amount}</td>
                            <td></td>
                        </tr>
                    ))}
                    <tr>
                        <td colSpan={3} className="text-end">
                            <input className="btn btn-sm btn-primary float-end" type="submit" value="buchen" onClick={book} />
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}