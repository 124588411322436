import React, { RefObject } from 'react';
import "./GanttChart.css";
import { XistTree } from '../../../app/project/xist/XistTree';
import { Project, Xist } from '../../../protos/protos/projects';
import Moment from 'react-moment';

interface GanttChartProps {
    project: Project;
    start: Date;
    end: Date;
}

export function GanttChart({ project }: GanttChartProps) {
    let start = new Date();
    let end = new Date();
    project.tasks.forEach((task) => {
        if (task.deadline && task.duration != null) {
            const deadline = new Date(task.deadline);
            end = end < deadline ? deadline : end;
            const base = new Date(task.deadline); // This is the end from where the duration is deducted
            base.setDate(base.getDate() - task.duration);
            start = start < base ? start : base;
        }
    });
    return (
        <XistTree
            className="row text-truncate h-100"
            projectId={project.id}
            nodes={project.tasks}
            renderNodes={
                (nodes: Xist[], ref?: RefObject<HTMLDivElement>) => renderNodes(project.id, nodes, start, end, ref)
            }
            flat={true}
        />
    )
}

const renderNodes =
    (projectId: number, nodes: Xist[], start: Date, end: Date, ref?: RefObject<HTMLDivElement>) => {
        const width_per_day = 25;
        React.useEffect(() => {
            if (ref && ref.current) {
                const a = start.getTime();
                const b = (new Date()).getTime();
                const diff = a > b ? a - b : b - a;
                const days = diff / (1000 * 360 * 24);
                ref.current.scrollTo((days + 2) * width_per_day, 0);
            }
        });
        const helper: (p: number, nodes: Xist[]) => any = (projectId: number, nodes: Xist[]) => {
            return (
                nodes.map((node, index) => {
                    const loadIsOpen = (key: string, initialValue: boolean) => {
                        if (typeof window === "undefined") {
                            return initialValue;
                        }
                        try {
                            const item = window.localStorage.getItem(key);
                            return item ? JSON.parse(item) : initialValue;
                        } catch (error) {
                            return initialValue;
                        }
                    }
                    const isOpen = loadIsOpen(`isOpen-${projectId}-${node.id}`, true);
                    if (!node.deadline) {
                        return;
                    }
                    if (node.duration === null || node.duration === undefined) {
                        return;
                    }
                    const deadline_days_from_start = Math.floor((new Date(node.deadline).getTime() - start.getTime()) / (1000 * 3600 * 24));
                    const leftDays = deadline_days_from_start - node.duration;
                    const left = node.duration === 0 ? 0 : leftDays * width_per_day;
                    const right = left + 25;
                    return (
                        <div key={index}>
                            <div style={
                                {
                                    "position": "relative",
                                    "left": `${left}%`,
                                    "right": `${right}`,
                                    "backgroundColor": node.name === "Element" ? "red" : "lime",
                                }
                            }>
                                <span style={{ position: "sticky", "left": "0px" }}>
                                    {node.name}
                                </span>
                            </div>
                            {!isOpen && (helper(projectId, node.children))}
                        </div>
                    );
                })
            );
        }
        const headers: any[] = [];
        let days = Math.floor((end.getTime() - start.getTime()) / (1000 * 3600 * 24));
        for (let i = 0; i <= days; i++) {
            const date = new Date();
            date.setDate(date.getDate() - (days - i));
            const el = (
                <span key={i} style={
                    {
                        'display': 'inline-block',
                        'left': `${width_per_day * i}%`,
                        'backgroundColor': (i % 2 == 0) ? "lightblue" : "lightgreen",
                        'width': `${width_per_day}%`
                    }
                }>
                    <Moment format='DD.MM.YYYY'>
                        {date}
                    </Moment>
                </span >
            )
            headers.push(el)
        }
        return (
            <div>
                {headers}
                {helper(projectId, nodes)}
            </div>
        );

    }