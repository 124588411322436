import React, { useState } from 'react';

import { XistCommentForm } from "../XistCommentForm";
import { XistComment } from "./XistComment";
import { create, RemoteContent, success } from '../../../utils/Loading';
//import { useTranslation } from 'react-i18next';

interface XistCommentsProps {
    url: string;
    comments_: any;
    projectId: number;
    collapsed?: boolean;
}

type Model = {
    comments: any | null;
    collapsed?: boolean
}

export function XistComments(props: XistCommentsProps) {
    //const [t, i18n] = useTranslation();
    const [collapsed, setCollapsed] = useState(props.collapsed);

    /**
     * async (_message: string) => {
        await fetch(`/api/v1/project/${project.id}/tasks/${xist.id}/comments`, withKeyAndJsonHeaders({
        method: 'POST',
        body: JSON.stringify({ comment: message })
        }));
                                             
        return;
        }
     */
    const render = (model: Model, dispatch: any) => {
        const onSaveComment = (message: string) => {
            dispatch(create(JSON.stringify({ comment: message })))
        };
        let marked: any[] = [];
        let comments_: any;
        if (model.comments) {
            marked = model.comments.filter((comment: any) => {
                return comment.marked === true
            }).map((comment: any) =>
                <XistComment key={comment.id} comment={comment} projectId={props.projectId} />
            );
            comments_ = model.comments.map((comment: any) =>
                <XistComment key={comment.id} comment={comment} projectId={props.projectId} />
            );
        } else {
            comments_ = <p>Noch keine Kommentare vorhanden</p>;
        }
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col">
                        <h4>
                            Kommentare ({comments_.length})
                            <span className='material-icons' onClick={() => setCollapsed(!collapsed)}>{collapsed ? "expand_more" : "expand_less"}</span>
                        </h4>
                    </div>
                </div>
                {!collapsed && (
                    <>
                        <div className="row">
                            <XistCommentForm onSave={onSaveComment} />
                            {
                                marked.length > 0 && (
                                    <div className="col">
                                        <h5>'Markierte:</h5>
                                        {marked}
                                    </div>
                                )
                            }
                        </div>

                        <div className="row">
                            <div className="col">
                                <h5>Kommentare:</h5>
                                {comments_}
                            </div>
                        </div>
                    </>)}
            </React.Fragment>
        );
    }
    return <RemoteContent<Model> url={props.url} initial={success({ comments: props.comments_ })} render={render} />
}