
import React, { useEffect, useState } from 'react';
import { TypedLoading, TypedLoadingState } from '../../utils/Loading';
import { SelectOption, SelectRole } from './UserList';
import { withKeyAndJsonHeaders } from '../../api';

interface User {
    email: string;
    roleId: number;
}

interface NewUserProps {
    onClickCancel: () => void;
    projectId: number;
    roles: SelectOption[];
}

export function NewUser({ onClickCancel, projectId, roles }: NewUserProps) {
    const [user, setUser] = useState<User>({ email: "", roleId: roles[0].id });
    const [loadingState, setLoadingState] = useState<TypedLoadingState<User, string>>({ state: 'success', data: user });

    useEffect(() => {
        const abortController = new AbortController();
        if (loadingState.state === 'loading') {
            const params = {
                'user_email': user.email,
                'role_id': user.roleId
            };
            fetch(`/api/v1/project/${projectId}/users`, withKeyAndJsonHeaders({
                method: 'POST',
                signal: abortController.signal,
                body: JSON.stringify(params)
            })).then(
                (response: Response) => {
                    if (response.status === 200) {
                        onClickCancel();
                    }
                    setLoadingState({ state: 'error', error: "Fehler beim Speichern der Daten!" });
                }
            );
        }
        return () => {
            abortController.abort();
        }
    })

    const onClickAddSave = (_e: React.ChangeEvent<HTMLFormElement>) => {
        setLoadingState({ state: 'loading', data: user });
    };
    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({ ...user, email: e.target.value });
    }
    const onChangeRole = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setUser({ ...user, roleId: parseInt(e.target.value) });
    }

    return (
        <TypedLoading state={loadingState} render={(_user) => {
            const error = null;
            return (
                <form onSubmit={onClickAddSave} className='col-sm-6 offset-sm-3'>
                    <div className="form-group">
                        <label className="control-label">E-Mail</label>
                        <input className="form-control form-control-sm" type="email" name="email" value={user.email} onChange={onChangeEmail} required />
                    </div>
                    <div className="form-group">
                        <label className="control-label">E-Mail</label>
                        <SelectRole inputName='role' options={roles} onChange={onChangeRole} value={user.roleId} />
                    </div>
                    <div className="form-group text-end">
                        {error && <div className="alert alert-danger">
                            {error}
                        </div>}
                        <button className="btn btn-outline-danger" onClick={onClickCancel}>Abbrechen</button>
                        <button className="btn btn-outline-success" type="submit">Hinzufügen</button>
                    </div>
                </form>);
        }} />
    )
}