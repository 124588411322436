import React from 'react';

interface TextInputProps {
    update: (value: string) => void;
    name: string;
    value: string;
    initial?: string;
}

export function TextInput(props: TextInputProps) {
    return (
        <input className="form-control form-control-sm" type="text" name={props.name} value={props.value}
            onChange={(e: React.FormEvent<HTMLInputElement>) => props.update(e.currentTarget.value)} />
    )
}