/**
 * @author Steffen Kittel
 */
import React from "react";

import { VersionId } from "./list/XistList";
import { XistBreadcrumbs } from "./XistBreadcrumbs";

import { XistInnerView } from "./Xist";
import { pending, RemoteContent, update } from "../../utils/Loading";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { CreateXist } from "./creation/CreateXist";
import { IssueId } from "./types";
import { Issue } from "../../../protos/protos/issues";

export interface ChangedEvent {
    id: number;
    xist_id: number;
    changed: VersionId;
}

export type ChangedCallback = (version: ChangedEvent) => void;

interface XistPanelProps {
    className: any;
    readOnly: boolean;
    onClickOpenXist: any;
    project: any;
    isProjectLead: boolean;
    version: VersionId | null;
}

export function XistPanel({
    className,
    readOnly,
    onClickOpenXist,
    project,
    isProjectLead,
    version
}: XistPanelProps) {
    const navigate = useNavigate();

    const params = useParams();
    let xistId: IssueId = 0 as IssueId;
    if (undefined !== params.xistId) {
        let temp = parseInt(params.xistId);
        if (!isNaN(temp)) {
            xistId = temp as IssueId;
        }
    }
    let urlSearchParams = new URLSearchParams();
    if (version) {
        urlSearchParams.append("version", version.toString());
    }

    const url = `/api/v1/project/${project.id}/tasks/${xistId}?${urlSearchParams.toString()}`;

    const render = (xist: any, dispatch: any) => {
        if (xist === null) {
            return ("Eror, xist is null");
        }
        const saveXist = (d: any) => {

            dispatch(update(d))
        }
        const setVersion = (version: any) => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("version", version)
            navigate({ search: searchParams.toString() });
        }
        if (xist.status === "ok") {
            setVersion(xist.version.version_id);
            return;
        }
        return (
            <XistView
                isProjectLead={isProjectLead}
                navigate={navigate}
                onClickOpenXist={onClickOpenXist}
                project={project}
                readOnly={readOnly}
                saveXist={saveXist}
                xist={xist}
                setVersion={setVersion}
                version={version}
                xistId={xistId}
                key={`${xistId}-${version}}`
                }
            />
        )
    }
    return (
        <div className={className}>
            <div className="row">
                <div className="col mx-3 mb-3 py-3 shadow-sm">
                    <RemoteContent<Issue> key={url} subscribe={{
                        event: `update-xist-${xistId}`,
                        callback: (version: any) => {
                            const v = JSON.parse(version);
                            const searchParams = new URLSearchParams(window.location.search);
                            searchParams.set("version", v.version.version_id)
                            navigate({ search: searchParams.toString() });
                            return null;
                        }
                    }} url={url} initial={pending()} render={render} />
                </div>
            </div>
        </div>
    );
}

export interface XistViewProps {
    xistId: number;
    project: any;
    readOnly: boolean;
    xist: Issue;
    onClickOpenXist: any;
    navigate: any;
    saveXist: any;
    version: any;
    setVersion: any;
    isProjectLead: any;
}

export function XistView({ xistId, project, xist, onClickOpenXist, navigate, saveXist, version, setVersion, isProjectLead, readOnly }: XistViewProps) {
    return (
        <>
            <XistBreadcrumbs key={xistId}
                xists={project.tasks}
                xist={xist}
                onClickOpenXist={onClickOpenXist}
                projectId={project.id}
            />
            <Routes>
                <Route
                    path="/createXist"
                    element={
                        <CreateXist
                            parent_id={xistId as IssueId}
                            directory={false}
                            project={project}
                            onCancel={() => {
                                navigate("..");
                            }}
                            onSuccess={(issue_id: number, _issue_version_id: number) => {
                                navigate(`../xist/${issue_id}`);
                            }}
                        />
                    }
                />
                <Route
                    path="/createXistList"
                    element={
                        <CreateXist
                            parent_id={xistId as IssueId}
                            directory={true}
                            project={project}
                            onCancel={() => {
                                navigate(-1);
                            }}
                            onSuccess={(issue_id: number, _issue_version_id: number) => {
                                navigate(`../xist/${issue_id}`);
                            }}
                        />
                    }
                />
                <Route path="/" element={
                    <XistInnerView
                        key={`${xistId}-${xist.version}`}
                        saveXist={saveXist}
                        version={version}
                        setVersion={setVersion}
                        isProjectLead={isProjectLead}
                        onClickOpenXist={onClickOpenXist}
                        project={project}
                        remoteXist={xist}
                        readOnly={readOnly}
                    />}
                />
            </Routes>
        </>
    )
}
