
/**
 * @author Steffen Kittel
 */
import React, { useContext, useReducer, useState } from 'react';
import Button from 'react-bootstrap/Button';

import { IssueVersion, VersionId, XistList } from './list/XistList';

import { XistProperties } from './properties/XistProperties';
import { XistComments } from './comments/XistComments';
import moment from 'moment';

import { BetaContext } from '../../greeter/Greeter';
import { ImageGallery } from '../../gallery/ImageGallery';
import { Editor } from '../../../ui/components/Editor';
import { XistFiles } from './files/XistFiles';
import { Xist, XistFile } from '../../../protos/protos/projects';
import { Issue } from '../../../protos/protos/issues';
import { RemoteContent } from '../../utils/Loading';

export type XistId = number & { __brand: 'XistId' };

import './Xist.css'
import { InputMorphToEditable, MorphToEditable } from '../../../ui/utils';

interface XistProps {
    readOnly: boolean;
    onClickOpenXist: any;
    project: any;
    isProjectLead: boolean;
    remoteXist: Issue;
    version: VersionId | null;
    setVersion: (version: VersionId | null) => void;
    saveXist: (xist: Xist) => void;
}

function reducer(xist: any, action: any) {
    let value: any = null;
    switch (action.type) {
        case 'deadline':
            const date = moment(action.value);
            value = date.format('DD.MM.YYYY');
            break;
        case 'description':
        case 'name':
            value = action.value;
            break;
        case 'status':
        case 'responsible':
            value = parseInt(action.value);
            break;
        default:
            console.error("invalid action ", action);
    }

    return { ...xist, [action.type]: value };
}

export function XistInnerView({ remoteXist, saveXist, readOnly, onClickOpenXist, project, isProjectLead, version, setVersion }: XistProps) {
    const beta = useContext(BetaContext);
    const [preview, setPreview] = useState<XistFile | null>(null);
    const [xist, dispatch] = useReducer(reducer, remoteXist);

    let isLatestVersion = false;
    if (xist && xist.versions) {
        const latest_version = Math.max.apply(Math, xist.versions.map((o: IssueVersion) => o.version_id));
        if (xist.version == latest_version) {
            isLatestVersion = true;
        }
    }

    const onFileUploadSuccess = () => {
        //refresh();
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className='row'>
                    <div className="col-12">
                        <h2>
                            <InputMorphToEditable text={xist.name} onChange={(e) => dispatch({ type: 'name', value: e.target.value })} />
                            <span className="float-end">
                                {beta && (
                                    <button className="btn btn-link border-0 material-icons p-0"
                                        onClick={() => {
                                            onClickOpenXist({
                                                project_id: project.id,
                                                id: xist.id,
                                                name: xist.name
                                            });
                                        }}>
                                        star
                                    </button>
                                )}
                                {!readOnly &&
                                    <Button variant="outline-success" className="float-end"
                                        onClick={() => saveXist(xist)}>{"Änderungen speichern"}</Button>}
                                {!readOnly &&
                                    <Button variant="outline-success" className="float-end"
                                        onClick={() => saveXist({ ...xist, archived_at: xist.archived_at ? undefined : new Date() })}>{xist.archived_at !== null ? "Wiederherstellen" : "Archivieren"}</Button>}
                            </span>
                            <br />
                        </h2>
                        {!isLatestVersion && (
                            <span className="text-warning">
                                Achtung! Sie bearbeiten gerade nicht die aktuellste Version!<br />M&ouml;gliche &Auml;nderungen in neueren Versionen werden ggf. &uuml;berschrieben!
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-9">
                        {remoteXist.xist_id != 0 && (<>
                            <div className='row'>
                                {preview && <ImageGallery xist={xist} setPreview={setPreview} onClose={() => { setPreview(null); }} selected={preview} />}
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4>Beschreibung</h4>
                                    <MorphToEditable
                                        plain={(toggle: any) => <div onClick={toggle}><div dangerouslySetInnerHTML={{ __html: xist.description?.length > 0 ? xist.description : "Platzhalter..." }}></div></div>}
                                        editable={() => <Editor defaultValue={xist.description} onTextChange={(html: string) => dispatch({ type: 'description', value: html })} readOnly={readOnly} />}
                                    />
                                </div>
                            </div>
                            <RemoteContent<Issue> url={`/api/v1/project/${project.id}/tasks/${xist.xist_id}`} render={(result: Issue) => {
                                return (
                                    <XistComments url={`/api/v1/project/${project.id}/tasks/${xist.xist_id}/comments`} projectId={project.id} comments_={result.comments} collapsed={xist.directory} />
                                );
                            }} />
                        </>)}
                        {xist.directory === true && (
                            <div className='col-12'>
                                <div className="row">
                                    <h3 className='h3'>Unterelemente</h3>
                                    <XistList
                                        onClickOpenXist={onClickOpenXist}
                                        xist={xist}
                                        project={project}
                                        isProjectLead={isProjectLead}
                                        readOnly={readOnly}
                                        beta={beta}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-12 col-md-3">
                        <div className='row'>
                            <XistProperties
                                key={xist.id}
                                project={project}
                                xist={xist}
                                className="col"
                                onChange={dispatch}
                                onChangeVersion={
                                    (e: React.ChangeEvent<HTMLInputElement>) => {
                                        const version = e.target.value;
                                        setVersion(parseInt(version) as VersionId);
                                    }}
                                isProjectLead={isProjectLead}
                                readOnly={readOnly}
                                displayVersion={version}
                            />
                        </div>
                        <div className="row">
                            <XistFiles project={project} xist={xist} onFileUploadSuccess={onFileUploadSuccess} readOnly={readOnly} setPreview={setPreview} />
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
