import React from "react";

import { Link, useMatch, useParams } from "react-router-dom";
import { RemoteContent } from "../utils/Loading";
import classNames from "classnames";

type onChangeQueryCallback = (query: string) => void;
type onClearCallback = () => void;

export interface SearchBarProps extends React.HTMLAttributes<HTMLDivElement> {
    onChangeQuery: onChangeQueryCallback;
    onClear: onClearCallback;
    matchPattern: string;
}

export function SearchBar({ onChangeQuery, className, onClear, matchPattern }: SearchBarProps) {
    const match = useMatch(matchPattern);
    const query = match?.params.query;
    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        const query = e.currentTarget.value;
        onChangeQuery(query);
    }
    return (
        <div className={classNames("row, pt-3", className)}>
            <div className="col">
                <div className="form-floating mb-3">
                    <div className="input-group input-group-sm">
                        <input className="form-control" type="text" id="searchbar_global"
                            value={query} onChange={onChange} placeholder="Suche"
                            autoCorrect="off" autoComplete="off" onFocus={onChange} onKeyDown={(e) => { if (e.key == 'Escape') { onClear(); } }} />
                        <span className="input-group-text" onClick={onClear}>
                            <span className="material-icons">clear</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
interface SearchResponse {
    query: string;
    result: SearchResult[];
}

export function SearchResults() {
    const { query: queryEncoded } = useParams();
    if (!queryEncoded) {
        return (
            <p>Kein Suchbegriff angegeben.</p>
        )
    }
    const query = decodeURIComponent(queryEncoded);
    const render = (response: SearchResponse) => {
        const results = response.result;
        return (
            <div className="row">
                <div className="col">
                    <div className="search-results mt-3">
                        <SearchResultsList query={query} results={results} />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <RemoteContent<SearchResponse> key={query} url={'/api/v1/search?' + new URLSearchParams({ query })} render={render} />
    )
}

interface SearchResultTask {
    task: {
        id: number;
        xist_id: number;
        name: string,
        project_name: string,
        project_closed?: Date,
        project_id: number,
        description: string,
    }
}

interface SearchResultUser {
    user: {
        name: string,
        surname: string
    }
}

type SearchResult = SearchResultTask | SearchResultUser;

interface SearchResultsListProps {
    query: string;
    results: SearchResult[];
}
function SearchResultsList({ query, results }: SearchResultsListProps) {
    if (results.length == 0) {
        return (
            <p>
                Leider keine Suchergebnisse für "{query}"
            </p>
        );
    }

    return (
        <>
            <p>Suchergebnisse für "{query}"</p>
            {results.map(
                (elem: SearchResult) => {
                    if ('task' in elem) {
                        const task = elem.task;
                        return (
                            <Link key={`t-${task.id}`}
                                to={`/project/${task.project_id}/xist/${task.xist_id}`}>
                                <div className="dropdown-item" key={task.id}>
                                    <span className="d-block small text-muted">
                                        {task.project_closed !== null && "Archiv: "} {task.project_name}
                                    </span>
                                    <span className={classNames({ "text-muted": task.project_closed !== null })}>
                                        {task.name}
                                    </span>
                                </div>
                            </Link>
                        )
                    } else if ('user' in elem) {
                        const user = elem.user;
                        return (
                            <li key={`user-${user.surname}${user.name}`}>
                                {user.surname} {user.name}
                            </li>
                        )
                    } else {
                        return false;
                    }
                })}
        </>
    )
}