/**
 * @author Steffen Kittel
 */

import React, { createContext, useContext } from "react";

import Moment from "react-moment";

import Dashboard from "../dashboard/DashboardPresenter";
import { XistsTab } from "./xist/XistTab";
import { pending, RemoteContent } from "../utils/Loading";
//import { useTranslation } from 'react-i18next';

import {
    Routes,
    Route,
    useParams,
    NavLink,
} from "react-router-dom";
import { SettingsTab } from "./administration/SettingsTab";
import { TeamTab } from "./team/TeamTab";
import { ProjectContextType } from "./ProjectContext";
import { EnvironmentContext } from "../greeter/Greeter";
import { AccountingTab } from "./accounting/AccountingTab";
import { Project } from "../../protos/protos/projects";

import { GanttChart } from "../../ui/components/gantt/GanttChart";

export enum DnDItemTypes {
    XIST = "xist",
}

export const ProjectContext = createContext<ProjectContextType>(null);

export function ProjectPresenterWrapper() {
    const params = useParams<string>();
    if (params.projectId == undefined) {
        return <div>Ungültige Projekt ID!</div>;
    }
    const projectId = parseInt(params.projectId);
    if (projectId == undefined) {
        return <div>Ungültige Projekt ID!</div>;
    }
    return <ProjectPresenter key={projectId} projectId={projectId} />
}

interface ProjectProps {
    projectId: number;
}

interface Result {
    project: Project
}

export default function ProjectPresenter(props: ProjectProps) {
    const { projectId } = props;
    const environment = useContext(EnvironmentContext);
    return <RemoteContent<Result>
        url={`/api/v1/project/${projectId}`}
        initial={pending()}
        subscribe={{ event: `update-project-${projectId}` }}
        render={(result: Result, dispatch: (action: any) => void) => render(result, environment, dispatch)}
    />
}

const render = ({ project }: Result, environment: any, _dispatch: any) => {
    const start = new Date();
    start.setDate(start.getDate() - 4);
    const end = new Date();
    const isProjectLead = project.users.reduce(
        (isProjectLead: any, user: any) => {
            if (isProjectLead) {
                return true;
            }
            return user.id == environment?.user.inner?.id && user.role == 1;
        },
        false,
    );
    const readOnly = project.closed === true;
    return (
        <ProjectContext.Provider value={project}>
            <div className="row h-100" key={project.id}>
                <div className="col-12 h-100 d-flex flex-column">
                    <div className="clearfix">
                        <span className="h1 float-start">
                            {project.name}{" "}
                            {project.closed === true && (
                                <span className="small text-muted">
                                    {"Archiviert am"}:{" "}
                                    <Moment format="DD.MM.YYYY">
                                        {project.closed_date}
                                    </Moment>
                                </span>
                            )}
                        </span>
                        <ul className="nav nav-tabs float-end">
                            <NavLink className="nav-link" to="dashboard">
                                Übersicht
                            </NavLink>
                            <NavLink to="xist" className="nav-link" >
                                Arbeitspakete und Dokumentation
                            </NavLink>
                            <NavLink to="accounting" className="nav-link" >
                                Buchführung
                            </NavLink>
                            <NavLink className="nav-link" to="gantt">
                                Gantt
                            </NavLink>
                            <NavLink className="nav-link" to="team">
                                Team
                            </NavLink>
                            {isProjectLead && (
                                <NavLink className="nav-link" to="settings">
                                    Einstellungen
                                </NavLink>
                            )}
                        </ul>
                    </div>
                    <div className="row flex-grow-1" style={{ height: "0px" }}>
                        <div className="col-12 d-flex flex-column h-100">
                            <div className="row flex-fill h-100">
                                <div className="col-12 tab-content d-flex flex-column h-100">
                                    <Routes>
                                        <Route
                                            path="dashboard"
                                            element={<Dashboard onClickOpenXist={() => console.error("not implemented onClickOpenXist!!")} projectId={project.id} />}
                                        />
                                        <Route
                                            path="xist/:xistId?/*"
                                            element={<XistsTab isProjectLead={isProjectLead} readOnly={readOnly} />}
                                        />
                                        <Route
                                            path="accounting/*"
                                            element={<AccountingTab project={project} isProjectLead={isProjectLead} readOnly={readOnly} />}
                                        />
                                        <Route
                                            path="gantt/*"
                                            element={<GanttChart start={start} end={end} project={project} />}
                                        />
                                        <Route
                                            path="settings"
                                            element={<SettingsTab project={project} isProjectLead={isProjectLead} readOnly={readOnly} />}
                                        />
                                        <Route
                                            path="team"
                                            element={<TeamTab project={project} isProjectLead={isProjectLead} readOnly={readOnly} />}
                                        />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ProjectContext.Provider>
    );
};