import React, { createContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Login } from "./Login";
import { Register } from "./Register";
import { RegistrationSuccessful } from "./RegistrationSuccessful";
import { PasswordRestore } from "./PasswordRestore";
import { RTC } from "../rtc/RTC";
import { Xistics } from "../Xistics";
import { connection, XUser } from "../api";
import { RemoteContent, success } from "../utils/Loading";
import { useBeta } from "../utils/useBeta";
import { User } from "../../protos/protos/users";
import { BinaryReader } from "@bufbuild/protobuf/wire";

//export type UserContextType = User;
export interface EnvironmentType {
    user: XUser;
    api: {
        key: string;
    };
}
export const EnvironmentContext = createContext<EnvironmentType | null>(null);
export const BetaContext = createContext<boolean>(false);

interface Model {
    user: XUser | null;
    error: string;
}

type Action = Int16Array;
interface GreeterProps {
    reactVersion: string;
}
export function Greeter(props: GreeterProps) {
    console.debug("Starting Greeter (React " + props.reactVersion + ")");

    const [beta, _] = useBeta();

    function onClickLogout(_e: React.MouseEvent<HTMLElement>): void {
        /*console.debug(
            "logout headers are",
            withKeyAndJsonHeaders({
                method: "DELETE",
                body: JSON.stringify({ key: u.key }),
            }),
        );
        fetch(
            "/api/v1/logout",
            withKeyAndJsonHeaders({
                method: "DELETE",
                body: JSON.stringify({ key: u.key }),
            }),
        ).finally(() => {
            localStorage.clear();
            const user = XUser.getInstance();
            user.key = null;
            user.user_id = null;
            setState({ state: 'pending' });
        });*/
    }
    const render = (model: Model, dispatch: any) => {
        console.debug("Greeter rendering", model)
        if (model.user === null || model.user.key === null) {
            connection.disconnect();
            return (
                <div className="container">
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Login onLoginSuccessful={(user: XUser) => { dispatch(success<Model>({ user: user, error: "No Error" })); }} />
                            }
                        />
                        <Route path="/register" element={<Register />} />
                        <Route
                            path="/registration-successful"
                            element={<RegistrationSuccessful />}
                        />
                        <Route
                            path="/passwordRestore"
                            element={<PasswordRestore />}
                        />
                        <Route path="/rtc" element={<RTC />} />
                        <Route path="/" element={<Navigate replace to="/" />} />
                    </Routes>
                </div>
            );
        }
        connection.connect({ key: model.user.key });
        return (
            <EnvironmentContext.Provider
                value={{ user: model.user, api: { key: model.user.key } }}
            >
                <BetaContext.Provider value={beta}>
                    <Routes>
                        <Route
                            path="/*"
                            element={<Xistics onClickLogout={onClickLogout} />}
                        />
                    </Routes>
                </BetaContext.Provider>
            </EnvironmentContext.Provider>
        );
    };
    return (
        <>
            <RemoteContent<Model, Action>
                url="/api/v1/user"
                decode={(input: Uint8Array | BinaryReader, _length?: number) => {
                    const user = new XUser(User.decode(input));
                    return { "user": user, error: "" } as Model
                }
                }
                initial={success({ user: XUser.getInstance(), error: "Bitte einloggen" })}
                render={render}
            />
        </>
    );

}
