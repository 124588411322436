import React from "react";
import { useParams } from "react-router-dom";
import { pending, RemoteContent } from "../../utils/Loading";
import { TransactionList } from "../transactions/TransactionList";

export type Ledger = {
    id: number,
    project_id: number,
    currency: string,
    name: string,
    description: string | null
}


export function LedgerView() {
    const { ledgerId } = useParams();
    const url = `/api/v1/accounting/ledger/${ledgerId}`;
    console.debug("ledger id is", ledgerId)
    console.debug("ledger url is ", url)
    return (
        <div key={ledgerId}>
            <RemoteContent<Ledger | null> url={url}
                initial={pending()} render={(ledger: any | null) => {
                    console.debug("ledger id is", ledger.ledger)
                    if (ledger.ledger === null) {
                        return (
                            <div>
                                Ledger nicht vorhanden!
                            </div>
                        )
                    }
                    return (
                        <div>
                            <h2>{ledger.ledger.name}</h2>
                            {ledger && <div><TransactionList ledgerId={ledger.ledger.id} /></div>}
                        </div>
                    )
                }} />
        </div>
    )
}