/**
 * @author Steffen Kittel
 */
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';

import { withKeyAndJsonHeaders } from '../../../api';
import { LoadingSpinner } from "../../../utils/Loading";
import { Project, Xist, XistFile } from '../../../../protos/protos/projects';
//import { useTranslation } from 'react-i18next';

interface XistFilesProps {
    project: Project;
    onFileUploadSuccess: () => void;
    xist: Xist;
    readOnly: boolean;
    setPreview: (file: XistFile) => void;
}

export function XistFiles({ project, onFileUploadSuccess, xist, readOnly, setPreview }: XistFilesProps) {
    //const [t, i18n] = useTranslation();
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState<number | null>();
    //const [files, setFiles] = useState([]);

    const delete_ = (e: React.MouseEvent, file: any) => {
        e.preventDefault();
        if (confirm("Wollen Sie die Datei wirklich löschen?")) {
            fetch(`/api/v1/project/${project.id}/files/${file.id}`, withKeyAndJsonHeaders({ method: 'DELETE' })).then(
                () => {
                    onFileUploadSuccess();
                }
            );
        }
    }

    const onProgress = (e: ProgressEvent) => {
        setProgress((e.loaded / e.total) * 100);
    }

    const onDrop = (files: any) => {
        setLoading(true);
        const formData = new FormData();
        files.forEach((file: any) => formData.append("file", file))

        const query = '?task_id=' + xist.id;
        let request = new XMLHttpRequest();
        request.open('POST', '/api/v1/project/' + project.id + '/files' + query, true);
        const headers = withKeyAndJsonHeaders();
        request.setRequestHeader("key", headers.headers.key);
        if (request.upload) {
            request.upload.onprogress = onProgress;
        }
        request.onload = function (_e: any) {
            if (request.readyState === 4) {
                if (request.status == 200) {
                    onFileUploadSuccess();
                    setLoading(false);
                } else {
                    setLoading(false)
                }
                setProgress(null);
            }
        };
        request.send(formData);
    }

    if (loading) {
        return (
            <div className="text-center m-auto">
                <LoadingSpinner />
                {progress}&#37;
            </div>
        );
    }
    const files_ = xist.files.map((file) => {
        return (
            <div key={file.url} className="clearfix">
                <a href={file.url}>{file.filename}</a>
                <span className="float-end">
                    {(file.mime_type.startsWith("image/") || file.mime_type === "application/pdf") &&
                        <a href="#" onClick={(e) => {
                            e.preventDefault();
                            setPreview(file);
                            return false;
                        }
                        }>
                            <i className="mdc-icon-button material-icons">search</i>
                        </a>}
                    <a href="#" onClick={(e) => delete_(e, file)}>
                        <i className="mdc-icon-button material-icons">delete</i>
                    </a>
                </span>
            </div>
        )
    }
    );

    return (
        <div className="col-12">
            <span>Dateien</span>
            <div>
                {files_}
            </div>

            {!readOnly &&
                <Dropzone onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })} className="text-center p-3 border">
                            <input {...getInputProps()} />
                            <p>Dateien hier ablegen<br />oder</p>
                            <button type="button">
                                Datei auswählen
                            </button>
                        </div>
                    )}
                </Dropzone>}
        </div>
    );
}