import React from 'react'; // ToDo: forwardRef

import { useQuill } from 'react-quilljs';

import 'quill/dist/quill.snow.css'

// Editor is an uncontrolled React component
export const Editor = ({ defaultValue, onTextChange, className }: any) => {
  const { quill, quillRef } = useQuill();

  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(defaultValue);
      quill.on('text-change', () => onTextChange(quill.root.innerHTML));
    }
  }, [quill]);

  return (
    <div className={className}>
      <div ref={quillRef}></div>
    </div>
  )
}