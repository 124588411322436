// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* GanttChart.css */
.gantt-container {
    width: 100%;
    overflow-x: scroll;
    position: relative;
    font-family: Arial, sans-serif;
  }
  
  .gantt-header {
    display: flex;
    border-bottom: 2px solid #ccc;
    margin-bottom: 10px;
  }
  
  .gantt-date {
    width: 50px;
    text-align: center;
    padding: 5px;
    background-color: #f0f0f0;
    font-size: 12px;
    font-weight: bold;
  }
  
  .gantt-body {
    position: relative;
  }
  
  .gantt-task {
    position: relative;
    margin-bottom: 10px;
    height: 10px;
  }
  
  .gantt-task-name {
    position: absolute;
    /*left: -100px; /* Keep task names visible */
    width: 150px;
    text-overflow: ellipsis;
    max-height: 15px;
    text-align: right;
    padding-right: 5px;
    line-height: 30px;
    font-weight: bold;
  }
  
  .gantt-task-bar {
    position: absolute;
    height: 20px;
    background-color: #4caf50;
    border-radius: 5px;
    top: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/ui/components/gantt/GanttChart.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,4CAA4C;IAC5C,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,SAAS;EACX","sourcesContent":["/* GanttChart.css */\n.gantt-container {\n    width: 100%;\n    overflow-x: scroll;\n    position: relative;\n    font-family: Arial, sans-serif;\n  }\n  \n  .gantt-header {\n    display: flex;\n    border-bottom: 2px solid #ccc;\n    margin-bottom: 10px;\n  }\n  \n  .gantt-date {\n    width: 50px;\n    text-align: center;\n    padding: 5px;\n    background-color: #f0f0f0;\n    font-size: 12px;\n    font-weight: bold;\n  }\n  \n  .gantt-body {\n    position: relative;\n  }\n  \n  .gantt-task {\n    position: relative;\n    margin-bottom: 10px;\n    height: 10px;\n  }\n  \n  .gantt-task-name {\n    position: absolute;\n    /*left: -100px; /* Keep task names visible */\n    width: 150px;\n    text-overflow: ellipsis;\n    max-height: 15px;\n    text-align: right;\n    padding-right: 5px;\n    line-height: 30px;\n    font-weight: bold;\n  }\n  \n  .gantt-task-bar {\n    position: absolute;\n    height: 20px;\n    background-color: #4caf50;\n    border-radius: 5px;\n    top: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
