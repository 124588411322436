import { io, Socket } from "socket.io-client";
import { User } from '../protos/protos/users';

export interface UserData {
    key: string | null;
    user_id: number | null;
}

export class XUser {
    private static _instance: XUser | null = null;
    public inner?: User;
    private _userdata: UserData;
    constructor(user?: User) {
        this.inner = user;
        this._userdata = { key: null, user_id: null };
        this.loadUserdata();
    }
    private loadUserdata(): void {
        const data = localStorage.getItem("userdata");
        if (!data) {
            return;
        }
        try {
            this._userdata = JSON.parse(data);
        } catch (error) {
            console.debug("unable to parse userdata ", error);
            localStorage.clear();
        }
    }
    private persist(): void {
        const data = JSON.stringify(this._userdata);
        console.debug("persisting userdata", data);
        localStorage.setItem("userdata", data);
    }

    public static getInstance(): XUser {
        if (XUser._instance) {
            return XUser._instance
        }
        XUser._instance = new XUser();
        return XUser._instance;
    }

    public setUserdata(userdata: UserData): void {
        this._userdata = userdata;
    }

    get key(): string | null {
        return this._userdata.key;
    }

    set key(key: string | null) {
        this._userdata.key = key;
        this.persist();
    }
    get user_id(): number | null {
        return this._userdata.user_id;
    }
    set user_id(user_id: number | null) {
        this._userdata.user_id = user_id;
        this.persist();
    }
}

export let withKeyAndJsonHeaders = (headers = {}) => {
    let _headers: any = {
        "Content-Type": "application/json",
    };
    const user = XUser.getInstance();
    if (user.key !== null) {
        _headers["key"] = user.key;
    }
    return {
        headers: _headers,
        ...headers,
    };
};

export let withKeyAndProtobufHeaders = (headers = {}) => {
    let _headers: any = {
        "Content-Type": "application/octet-stream",
    };
    const user = XUser.getInstance();
    if (user.key !== null) {
        _headers["key"] = user.key;
    }
    return {
        headers: _headers,
        ...headers,
    };
};

class Connection {
    socket: Socket;
    constructor() {
        this.socket = io("/xistics", {
            path: "/api/v1/socket.io",
            autoConnect: false,
        });
        this.on = this.on.bind(this);
        this.off = this.off.bind(this);
        this.sock = this.sock.bind(this);
    }

    on(event: string, callback: any) {
        return this.socket.on(event, callback);
    }

    off(event: string, callback: any) {
        return this.socket.off(event, callback);
    }

    sock() {
        return this.socket;
    }

    connect(extraHeaders: any) {
        this.socket.disconnect();
        this.socket = io("/xistics", {
            path: "/api/v1/socket.io",
            transports: ["websocket"],
            autoConnect: false,
            query: extraHeaders
        });
        this.socket.connect();
    }

    disconnect() {
        this.socket.disconnect()
    }
}

export let connection = new Connection();
