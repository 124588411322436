import React from "react";
import { RemoteContent, TypedLoadingState } from "../utils/Loading";
import { Ledger, LedgerView } from "./ledger/Ledger";
import { Link, Route, Routes } from "react-router-dom";

export interface AccountingResponse {
    ledgers: Ledger[]
}

const reducer = (_prev: TypedLoadingState<AccountingResponse | null, string>, _action: any) => {
    return {state: "pending"} as TypedLoadingState<AccountingResponse | null, string>
}

export function Accounting() {
    return (
        <>
            <RemoteContent<AccountingResponse | null> url="/api/v1/accounting" initial={{state:'pending'}} reducer={reducer}
                render={(data: AccountingResponse | null) => {
                    return (
                        <>
                            <div>
                                <h1>Bücher</h1>
                                <ul>
                                    {data?.ledgers.map((ledger: Ledger) => <li key={ledger.id}><Link to={`/accounting/ledger/${ledger.id}`}>{ledger.name}</Link></li>)}
                                </ul>
                            </div >
                            <div>
                                <Routes>
                                    <Route path="/ledger/:ledgerId" element={<LedgerView />} />
                                </Routes>
                            </div>
                        </>
                    )
                }} />
        </>
    )
}