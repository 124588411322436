// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// versions:
//   protoc-gen-ts_proto  v2.6.1
//   protoc               v5.29.3
// source: protos/users.proto

/* eslint-disable */
import { BinaryReader, BinaryWriter } from "@bufbuild/protobuf/wire";

export const protobufPackage = "ek.v1";

export interface User {
  id: number;
  surname?: string | undefined;
  name?: string | undefined;
  role?: number | undefined;
  mail?: string | undefined;
  email?: string | undefined;
  street?: string | undefined;
  zip?: string | undefined;
  city?: string | undefined;
  phone?: string | undefined;
  fax?: string | undefined;
}

function createBaseUser(): User {
  return {
    id: 0,
    surname: undefined,
    name: undefined,
    role: undefined,
    mail: undefined,
    email: undefined,
    street: undefined,
    zip: undefined,
    city: undefined,
    phone: undefined,
    fax: undefined,
  };
}

export const User: MessageFns<User> = {
  encode(message: User, writer: BinaryWriter = new BinaryWriter()): BinaryWriter {
    if (message.id !== 0) {
      writer.uint32(8).int32(message.id);
    }
    if (message.surname !== undefined) {
      writer.uint32(18).string(message.surname);
    }
    if (message.name !== undefined) {
      writer.uint32(26).string(message.name);
    }
    if (message.role !== undefined) {
      writer.uint32(32).int32(message.role);
    }
    if (message.mail !== undefined) {
      writer.uint32(42).string(message.mail);
    }
    if (message.email !== undefined) {
      writer.uint32(50).string(message.email);
    }
    if (message.street !== undefined) {
      writer.uint32(58).string(message.street);
    }
    if (message.zip !== undefined) {
      writer.uint32(66).string(message.zip);
    }
    if (message.city !== undefined) {
      writer.uint32(74).string(message.city);
    }
    if (message.phone !== undefined) {
      writer.uint32(82).string(message.phone);
    }
    if (message.fax !== undefined) {
      writer.uint32(90).string(message.fax);
    }
    return writer;
  },

  decode(input: BinaryReader | Uint8Array, length?: number): User {
    const reader = input instanceof BinaryReader ? input : new BinaryReader(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUser();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1: {
          if (tag !== 8) {
            break;
          }

          message.id = reader.int32();
          continue;
        }
        case 2: {
          if (tag !== 18) {
            break;
          }

          message.surname = reader.string();
          continue;
        }
        case 3: {
          if (tag !== 26) {
            break;
          }

          message.name = reader.string();
          continue;
        }
        case 4: {
          if (tag !== 32) {
            break;
          }

          message.role = reader.int32();
          continue;
        }
        case 5: {
          if (tag !== 42) {
            break;
          }

          message.mail = reader.string();
          continue;
        }
        case 6: {
          if (tag !== 50) {
            break;
          }

          message.email = reader.string();
          continue;
        }
        case 7: {
          if (tag !== 58) {
            break;
          }

          message.street = reader.string();
          continue;
        }
        case 8: {
          if (tag !== 66) {
            break;
          }

          message.zip = reader.string();
          continue;
        }
        case 9: {
          if (tag !== 74) {
            break;
          }

          message.city = reader.string();
          continue;
        }
        case 10: {
          if (tag !== 82) {
            break;
          }

          message.phone = reader.string();
          continue;
        }
        case 11: {
          if (tag !== 90) {
            break;
          }

          message.fax = reader.string();
          continue;
        }
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skip(tag & 7);
    }
    return message;
  },

  fromJSON(object: any): User {
    return {
      id: isSet(object.id) ? globalThis.Number(object.id) : 0,
      surname: isSet(object.surname) ? globalThis.String(object.surname) : undefined,
      name: isSet(object.name) ? globalThis.String(object.name) : undefined,
      role: isSet(object.role) ? globalThis.Number(object.role) : undefined,
      mail: isSet(object.mail) ? globalThis.String(object.mail) : undefined,
      email: isSet(object.email) ? globalThis.String(object.email) : undefined,
      street: isSet(object.street) ? globalThis.String(object.street) : undefined,
      zip: isSet(object.zip) ? globalThis.String(object.zip) : undefined,
      city: isSet(object.city) ? globalThis.String(object.city) : undefined,
      phone: isSet(object.phone) ? globalThis.String(object.phone) : undefined,
      fax: isSet(object.fax) ? globalThis.String(object.fax) : undefined,
    };
  },

  toJSON(message: User): unknown {
    const obj: any = {};
    if (message.id !== 0) {
      obj.id = Math.round(message.id);
    }
    if (message.surname !== undefined) {
      obj.surname = message.surname;
    }
    if (message.name !== undefined) {
      obj.name = message.name;
    }
    if (message.role !== undefined) {
      obj.role = Math.round(message.role);
    }
    if (message.mail !== undefined) {
      obj.mail = message.mail;
    }
    if (message.email !== undefined) {
      obj.email = message.email;
    }
    if (message.street !== undefined) {
      obj.street = message.street;
    }
    if (message.zip !== undefined) {
      obj.zip = message.zip;
    }
    if (message.city !== undefined) {
      obj.city = message.city;
    }
    if (message.phone !== undefined) {
      obj.phone = message.phone;
    }
    if (message.fax !== undefined) {
      obj.fax = message.fax;
    }
    return obj;
  },

  create<I extends Exact<DeepPartial<User>, I>>(base?: I): User {
    return User.fromPartial(base ?? ({} as any));
  },
  fromPartial<I extends Exact<DeepPartial<User>, I>>(object: I): User {
    const message = createBaseUser();
    message.id = object.id ?? 0;
    message.surname = object.surname ?? undefined;
    message.name = object.name ?? undefined;
    message.role = object.role ?? undefined;
    message.mail = object.mail ?? undefined;
    message.email = object.email ?? undefined;
    message.street = object.street ?? undefined;
    message.zip = object.zip ?? undefined;
    message.city = object.city ?? undefined;
    message.phone = object.phone ?? undefined;
    message.fax = object.fax ?? undefined;
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends globalThis.Array<infer U> ? globalThis.Array<DeepPartial<U>>
  : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function isSet(value: any): boolean {
  return value !== null && value !== undefined;
}

export interface MessageFns<T> {
  encode(message: T, writer?: BinaryWriter): BinaryWriter;
  decode(input: BinaryReader | Uint8Array, length?: number): T;
  fromJSON(object: any): T;
  toJSON(message: T): unknown;
  create<I extends Exact<DeepPartial<T>, I>>(base?: I): T;
  fromPartial<I extends Exact<DeepPartial<T>, I>>(object: I): T;
}
