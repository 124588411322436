/**
 * @author Steffen Kittel
 */

import React from "react";

import { Link, NavLink } from "react-router-dom";
import classNames from "classnames";
import { RemoteContent } from "../utils/Loading";
//import { useTranslation } from 'react-i18next';

interface Project {
    id: number;
    name: string;
    closed: boolean;
    closed_date: Date;
}

interface ProjectResponse {
    projects: Project[]
}

interface ProjectListItemProps {
    projectId: number;
    projectName: string;
    onItemClick: () => void;
}

function ProjectListItem(props: ProjectListItemProps) {
    return (
        <React.Fragment>
            <span >
                <NavLink
                    to={`/project/${props.projectId}/xist`}

                    className={({ isActive, isPending }) =>
                        classNames("d-block text-nowrap", isPending ? "pending" : isActive ? "link-success" : "")
                    }
                    onClick={props.onItemClick}
                >
                    {props.projectName}
                </NavLink>
            </span>
        </React.Fragment>
    );
}
interface ProjectListProps {
    onItemClick: () => void
}
export function ProjectList({ onItemClick }: ProjectListProps) {
    //const [t, i18n] = useTranslation();

    const render = (data: ProjectResponse) => {
        const projectsOpen = data.projects.filter(
            (project: Project) => project.closed == false,
        );
        const projectsClosed = data.projects.filter(
            (project: Project) => project.closed == true,
        );
        return (
            <div className="project-list">
                <Link to="/projectNew">Neues Projekt</Link>
                <h6 className="text-muted pt-3">Aktiv</h6>
                {projectsOpen && (
                    <>
                        {projectsOpen.map((project: Project) => {
                            return (
                                <ProjectListItem
                                    key={project.id}
                                    projectName={project.name}
                                    projectId={project.id}
                                    onItemClick={onItemClick}
                                />
                            );
                        })}
                    </>
                )}
                {(projectsClosed && projectsClosed.length > 0) && (
                    <>
                        <h6 className="text-muted pt-3">
                            Inaktiv
                            <a
                                role="button"
                                href="#"
                                className="float-end"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#projectsInactive"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                            >
                                <i className="material-icons">expand_circle_down</i>
                            </a>
                        </h6>
                        <div className="collapse" id="projectsInactive">
                            {projectsClosed && (
                                <>

                                    {projectsClosed.map((project: Project) => (
                                        <ProjectListItem
                                            key={project.id}
                                            projectName={project.name}
                                            projectId={project.id}
                                            onItemClick={onItemClick}
                                        />
                                    ))}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        );
    }
    return <RemoteContent<ProjectResponse> subscribe={{ "event": "update-project-list" }} url="/api/v1/projects" render={render} />
}
