import React, { useState } from 'react';

import './utils.sass'

export function InputMorphToEditable({ text, onChange }: { text: string, onChange: (e: React.ChangeEvent<HTMLInputElement>) => void }) {
    return (
        <MorphToEditable
            plain={(toggle: any) => <div className="morphable"><span onClick={toggle}>{text}</span></div>}
            editable={(toggle: any) => (<input type="text" name="name" value={text} onChange={(e) => { onChange(e); }} onBlur={toggle} />)}
        />

    )
}

export function MorphToEditable({ plain, editable }: { plain: any, editable: any }) {
    const [editing, setEditing] = useState(false);
    const toggle = () => {
        console.debug("toggle!!! to", !editing)
        setEditing(!editing);
    }
    return (
        <div className='morphable'>
            {
                editing ? editable(toggle) : plain(toggle)
            }
        </div>
    )
}