import React, { useState } from "react";
import { withKeyAndJsonHeaders } from "../../api";
import { AccountGroup } from "../../../protos/protos/accounting";

export interface Account {
    account_group_id: number | null;
    name: string;
}

interface CreateAccountProps {
    ledger_id: number;
    account_groups: AccountGroup[];
}

export function CreateAccount(props: CreateAccountProps) {
    const [state, setState] = useState("new")
    const [account, setAccount] = useState<Account>({ name: '', account_group_id: null });
    const onSubmit = async (e: any) => {
        e.preventDefault();
        await fetch(`/api/v1/accounting/ledger/${props.ledger_id}/accounts`, withKeyAndJsonHeaders({
            method: 'POST',
            body: JSON.stringify({
                account_name: account.name,
                account_group_id: account.account_group_id
            })
        }));
        setState("created");
        console.debug("doing stuff here", account);
    };
    if (state === "new") {
        return (
            <div>
                Konto erstellen:
                <CreateAccountForm onSubmit={onSubmit} account={account} setAccount={setAccount} account_groups={props.account_groups} />
            </div>
        )
    } else {
        return (
            <>Account erstellt...</>
        )
    }
}

interface CreateAccountFormProps {
    account: Account;
    setAccount: any;
    onSubmit: (e: any) => {};
    account_groups: AccountGroup[];
}

export function CreateAccountForm(props: CreateAccountFormProps) {
    const account = props.account;
    const setAccount = props.setAccount;
    return (
        <form onSubmit={props.onSubmit}>
            <input type='text' name="name" value={account.name} onChange={(e) => setAccount({ ...account, name: e.target.value, })} minLength={1} required={true}/>
            <select onChange={(e) => setAccount({ ...account, account_group_id: parseInt(e.target.value) })} required={true}>
                <option></option>
                {props.account_groups.map((group) => <option key={group.id} value={group.id}>{group.kind} - {group.name}</option>)}
            </select>
            <input type='submit' className='btn btn-primary btn-sm' value="erstellen" />
        </form>
    )
}