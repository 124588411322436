/**
 * @author Steffen Kittel
 */
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { Column, useTable } from 'react-table';

import Moment from 'react-moment';

import './XistList.css';

import { useLocalStorage } from '../../../utils/useLocalStorage';
import { CreateXistWithFiles } from '../creation/CreateXistWithFiles';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Project, Xist } from '../../../../protos/protos/projects';
import { Issue } from '../../../../protos/protos/issues';
import { XistListItem } from './XistListItem';
import { filter } from '../XistTree';


export type VersionId = number & { __brand: 'VersionId' };
export interface IssueVersion {
    version_id: VersionId;
    timestamp: Date;
}

export interface XistComment {
    id: number;
    task_id: number;
    comment: string;
    created: Date;
    user_name: string;
    user_surname: String;
    user_email: string;
    hidden: boolean;
    marked: boolean;
    deleted: boolean;
}

export enum XistStatus {
    PENDING = 0,
    IN_PROGRESS = 1,
    DONE = 2
}


interface XistListProps {
    beta: boolean;
    project: any;
    onClickOpenXist: any;
    isProjectLead: any;
    readOnly: boolean;
    xist: Issue;
}
export function XistList({ beta, project, onClickOpenXist, isProjectLead, readOnly, xist }: XistListProps) {
    //const [t, i18n] = useTranslation();    
    return (
        <div className="row">
            <List xist={xist} onClickOpenXist={onClickOpenXist} isProjectLead={isProjectLead} readOnly={readOnly} beta={beta} project={project} />
        </div>
    );
}
interface ListProps {
    xist: Issue;
    onClickOpenXist: any;
    isProjectLead: boolean;
    readOnly: boolean;
    beta: boolean;
    project: Project;
}

function List({ xist, onClickOpenXist, readOnly, project }: ListProps) {
    const [showArchived, setShowArchived] = useLocalStorage(`showArhived-${xist.xist_id}`, false);
    const [showCompleted, setShowCompleted] = useLocalStorage(`showCompleted-${xist.xist_id}`, true);

    const columns = React.useMemo<Column[]>(() => [
        {
            Header: '',
            id: 'directory',
            accessor: 'directory',
            Cell: (e: any) => {
                const xist = e.row.original;
                return xist.directory ?
                    <i className="material-icons">folder</i> :
                    <i className="material-icons">task</i>;
            },
            className: 'text-center',
            maxWidth: 10,
            width: 10
        },
        {
            Header: 'Name',
            id: 'name',
            accessor: 'name',
            Cell: (e: any) => {
                const xist = e.row.original;
                return (
                    <XistListItem
                        beta={false}
                        key={xist.issueId}
                        projectId={project.id}
                        onClickOpenXist_={onClickOpenXist}
                        xist={xist}
                        project={project}
                    />)
            }
        }, {
            Header: () => <div className='text-center'>Status</div>,
            accessor: 'status',
            Cell: (e: any) => {
                let xist = e.row.original;
                if (xist.status !== null) {
                    return <span className={`xist-status xist-status-${xist.status} text-center`}></span>
                }
                return (
                    <span className="xist-status xist-status-2 text-center"></span>
                )

            },
            maxWidth: 100
        }, {
            Header: () => <div className='text-center'>Fällig</div>,
            accessor: 'deadline',
            Cell: (e: any) => {
                const deadline = e.row.original.deadline;
                if (deadline !== null && deadline !== "") {
                    return <Moment format="DD.MM.YYYY">{deadline}</Moment>
                }
                return <span>-</span>
            },
            className: 'text-center',
            maxWidth: 100
        }], []);

    let defaultSorted = [{ id: "directory", desc: true }, { id: "name", asc: true }];
    let [queryParams, _] = useSearchParams();
    //const queryParams = queryString.parse(history.location.search);
    let sorton = queryParams.get('sorton');
    if (sorton) {
        defaultSorted = [];
        for (let field of sorton.split(",")) {
            const column = field.split(";");
            if (column[1] == "desc") {
                defaultSorted.push({ id: column[0], desc: true });
            } else {
                defaultSorted.push({ id: column[0], asc: true });
            }
        }
    }

    /*const onSortedChange = (newSorted, column, shiftKey) => {
        const columns = [];
        for (let column of newSorted) {
            if (column.desc === true) {
                columns.push(column.id + ";" + "desc");
            } else if (undefined === column.asc || column.asc === true) {
                columns.push(column.id + ";" + "asc");
            }
        }
        /*history.replace({
            pathname: history.location.pathname,
            search: "?" + queryString.stringify({ sorton: columns.join(',') })
        });*/
    // ToDo Fix
    //throw new Error("Implement navigation")
    //};*/

    let data = xist.children.filter((i: Xist) => { return filter(showArchived, showCompleted, i); });

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    return (
        <div className="col-12">
            {!readOnly && <div className="row">
                <p className="text-end col-12">
                    <Link className='btn btn-outline-primary btn-sm' to="createXist">Xist erstellen</Link>
                    &nbsp;
                    <Link className='btn btn-outline-primary btn-sm' to="createXistList">Xistsammlung erstellen</Link>
                </p>
            </div>}
            <CreateXistWithFiles project_id={project.id} parent_id={xist.xist_id} />
            <div className="row">
                <div className="col text-end">
                    <div className='d-inline mx-2'><label>Archivierte anzeigen</label> <input type='checkbox' checked={showArchived} onChange={() => setShowArchived(!showArchived)} /></div>
                    <div className='d-inline'><label>Erledigte anzeigen</label> <input type="checkbox" checked={showCompleted} onChange={() => { setShowCompleted(!showCompleted) }} /></div>
                </div>
            </div>
            <NoXists xist={xist} />
            <XistsCompleted xist={xist} />
            {data.length != 0 && (<table className='w-100' {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {
                                headerGroup.headers.map((column: any) => (
                                    <th {...column.getHeaderProps()}>
                                        {
                                            column.render('Header')
                                        }
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                    }
                </thead>
                <tbody {...getTableBodyProps()}>
                    {
                        rows.map(row => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {
                                        row.cells.map(cell => {
                                            return (
                                                <td {...cell.getCellProps()}>
                                                    {
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>)}
        </div>
    );
}
interface XistsCompletedProps {
    xist: Issue;
}

function XistsCompleted(props: XistsCompletedProps) {
    const completed = ((props.xist.children.length > 0) && (props.xist.children.filter((x: any) => {
        return x.status == 2
    }).length == props.xist.children.length));
    if (completed) {
        return (
            <Row>
                <Col className='text-center'>
                    <p>Alle Xists erledigt!</p>
                </Col>
            </Row>
        )
    }
    return null;
}

interface NoXistsProps {
    xist: Issue;
}

function NoXists(props: NoXistsProps) {
    if (props.xist.children.length == 0) {
        return (
            <Row>
                <Col className='text-center'>
                    <p>Keine Xists vorhanden</p>
                </Col>
            </Row>
        )
    }
    return null;
}
