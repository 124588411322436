import React from 'react';
import { withKeyAndJsonHeaders } from '../../api';
import { pending, RemoteContent } from '../../utils/Loading';
import { useParams } from 'react-router-dom';
import { AccountingResponse } from '../../accounting/Accounting';
import { TransactionList } from '../../accounting/transactions/TransactionList';
import { Tab, Tabs } from '../../../ui/components/Tabs';
import { Accounts } from '../../accounting/accounts/Accounts';
import { Project } from '../../../protos/protos/projects';

interface AccountingTabProps {
    project: Project;
    isProjectLead: boolean;
    readOnly: boolean;
}

export function AccountingTab({ project, isProjectLead, readOnly }: AccountingTabProps) {
    console.debug("AccountingTab", project, isProjectLead, readOnly);
    const { ledgerId } = useParams();
    const url = `/api/v1/accounting/ledger/${ledgerId}`;
    console.debug("ledger id is", ledgerId)
    console.debug("ledger url is ", url)
    return (
        <>
            <RemoteContent<AccountingResponse | null> url="/api/v1/accounting" initial={pending()}
                render={(data: AccountingResponse | null) => {
                    if (data === null) {
                        return (
                            <div>
                                Error, data is null!
                            </div>
                        );
                    }
                    let ledger = null;
                    for (let l of data.ledgers) {
                        if (l.project_id == project.id) {
                            ledger = l;
                            break;
                        }
                    }

                    if (ledger) {
                        const tabs: Tab[] = [
                            { name: "Buchungen", path: "ledger", element: <TransactionList ledgerId={ledger.id} /> },
                            { name: "Konten", path: "accounts", element: <Accounts ledgerId={ledger.id} /> }
                        ];
                        return (
                            <Tabs tabs={tabs} />
                        );
                    } else {
                        return (
                            <div>
                                Buchführung noch nicht konfiguriert.
                                <CreateLedger project={project} />
                            </div>
                        )
                    }
                }} />
        </>
    )
}

interface CreateLedgerProps {
    project: Project
}

function CreateLedger({ project }: CreateLedgerProps) {
    const submit = () => {
        fetch("/api/v1/accounting/ledgers", withKeyAndJsonHeaders({
            method: 'POST',
            body: JSON.stringify({
                project_id: project.id,
                name: project.name,
                currency: "EUR"
            })
        }));
    }
    return (
        <div>
            <input type="submit" onClick={submit} value="erstellen" />
        </div>
    )
}