import React from 'react';
import { NavLink, Route, Routes } from 'react-router-dom';

export interface Tab {
    name: string,
    path: string,
    element: React.ReactNode
}

interface TabsProps {
    tabs: Tab[],
    className?: string
}

export function Tabs(props: TabsProps) {
    return (
        <div>
            <div>
                <ul className={`nav nav-tabs ${props.className}`}>
                    {props.tabs.map(tab => (
                        <li className='nav-item'>
                            <NavLink className={({ isActive, }) => isActive ? "nav-link active" : "nav-link"} to={tab.path} >{tab.name}</NavLink>
                        </li>))
                    }
                </ul>
            </div>
            <div>
                <Routes>
                    {props.tabs.map((tab) => (
                        <Route
                            path={tab.path}
                            element={tab.element}
                        />
                    ))}
                </Routes>
            </div>
        </div>
    )
}