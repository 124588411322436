import React from 'react';

interface FormGroupWithLabelProps {
    children: React.ReactNode,
    label: string
}

export function FormGroupWithLabel(props: FormGroupWithLabelProps) {
    return (
        <div className="form-group">
            <label className="control-label">{props.label}</label>
            <div className="controls">
                {props.children}
            </div>
        </div>
    )
}