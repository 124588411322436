/**
 * @author Steffen Kittel
 */
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { NavLink } from 'react-router-dom';

import { withKeyAndJsonHeaders } from "../../api";
import { DnDItemTypes } from '../ProjectPresenter';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { Xist } from '../../../protos/protos/projects';
import { XistStatus } from './list/XistList';

interface getTreeFromFlatDataProps {
    flatData: Xist[];
    getKey: (xist: Xist) => number;
    getParentKey: (xist: Xist) => number | null;
    rootKey: number | null;
}

function getTreeFromFlatData(props: getTreeFromFlatDataProps): Xist[] {
    let flatData = props.flatData.filter((value) => !(value.id === props.rootKey));
    let nodes = flatData.filter((node) => {
        return props.getParentKey(node) === props.rootKey;
    });
    nodes.forEach((child: Xist) => {
        flatData = flatData.filter((node) => node.id !== child.id)
    });
    nodes.forEach((node: Xist) => {
        node.children = getTreeFromFlatData({
            flatData: flatData,
            getKey: props.getKey,
            getParentKey: props.getParentKey,
            rootKey: props.getKey(node),
        });

    });
    return nodes;
}
interface XistTreeProps {
    className: string;
    projectId: number;
    nodes: Xist[];
    renderNode?: (node: Xist) => any;
    renderNodes?: (node: Xist[], ref?: RefObject<HTMLDivElement>) => any;
    flat?: boolean;
}

const pass_directory = (issue: Xist): boolean => issue.directory;
const is_archived = (issue: Xist) => (issue.archived_at !== undefined) && (issue.archived_at !== null);
const is_completed = (issue: Xist): boolean => issue.status === XistStatus.DONE;

export const filter = (showArchived: boolean, showCompleted: boolean, issue: Xist): boolean => {
    if (pass_directory(issue)) {
        return true;
    }
    const archived = is_archived(issue);
    const completed = is_completed(issue);
    let result = false;
    if (archived && completed) {
        if (showArchived || showCompleted) {
            result = true;
        }
    } else if (archived) {
        if (showArchived) {
            result = true;
        }
    } else if (completed) {
        if (showCompleted) {
            result = true;
        }
    } else {
        result = true;
    }
    return result;
}

export function XistTree({ className, projectId, nodes, renderNodes, flat }: XistTreeProps) {
    const abortController = useRef<AbortController>(new AbortController());

    const [showArchived, setShowArchived] = useLocalStorage(`showArhived-tree-${projectId}`, false);
    const [showCompleted, setShowCompleted] = useLocalStorage(`tree-${projectId}-showCompleted`, false);
    const tree = useMemo(() => {
        const flatData = nodes.filter((v: Xist) => { return filter(showArchived, showCompleted, v) });
        const tree =
        {
            id: 0,
            xist_id: 0,
            directory: true,
            name: 'Xists',
            parent_id: null,
            status: 0,
            files: [],
            children: getTreeFromFlatData({
                flatData,
                getKey: (node: Xist) => node.id,
                getParentKey: (node: Xist) => {
                    if (node.parent_id === null || node.parent_id === undefined) {
                        return null;
                    }
                    return node.parent_id;
                },
                rootKey: null,
            })
        };
        return tree;
    }, [showCompleted, showArchived, projectId]);

    const onMoveNode = (task_id: number, new_parent_id: number) => {
        abortController.current.abort()
        const newAbortController = new AbortController();
        abortController.current = newAbortController;
        let rename_request = {
            parent_id: new_parent_id ? new_parent_id : 0,
            id: task_id
        };
        fetch(`/api/v1/project/${projectId}/tasks/${task_id}`, withKeyAndJsonHeaders({
            signal: newAbortController.signal,
            method: 'PUT',
            body: JSON.stringify(rename_request)
        })).then(
            async () => {
                //update();
            }
        ).finally(() => {
            console.debug("finally do something!");
        })
    }
    const [key, setKey] = useState(0);
    const redraw = () => {
        setKey(key + 1);
    }
    const nodesRef = useRef<HTMLDivElement>(null);
    return (
        <div className={className}>
            <div className='col h-100'>
                <div className='row'>
                    <div className="text-end">
                        <label>Archivierte anzeigen</label> <input type='checkbox' checked={showArchived} onChange={() => setShowArchived(!showArchived)} />
                        <br />
                        <label>Erledigte anzeigen</label> <input type='checkbox' checked={showCompleted} onChange={() => setShowCompleted(!showCompleted)} />
                    </div>
                </div>
                {renderNodes ? (
                    <div className='row h-100 overflow-y-scroll'>
                        <div className='col-3 overflow-x-scroll'>
                            <Tree tree={tree} projectId={projectId} onMoveNode={onMoveNode} redraw={redraw} />
                        </div>
                        <div className='col-9 overflow-x-scroll' ref={nodesRef} key={key}>
                            {renderNodes(tree.children, nodesRef)}
                        </div>
                    </div>
                ) : (
                    <Tree tree={tree} projectId={projectId} onMoveNode={onMoveNode} flat={flat} />
                )
                }
            </div>
        </div>
    );
}
interface TreeProps {
    tree: any;
    projectId: number;
    onMoveNode: (task_id: number, new_parent_id: number) => void;
    renderNode?: (node: Xist) => any;
    renderNodes?: (node: Xist[]) => any;
    flat?: boolean;
    redraw?: () => void;
}
function Tree({ tree, projectId, onMoveNode, flat, redraw }: TreeProps) {
    const [isOpen, setIsOpen] = useLocalStorage(`isOpen-${projectId}-${tree.id}`, true);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        redraw && redraw();
    }, [isOpen]);

    const [{ opacity }, dragRef] = useDrag(() => ({
        type: DnDItemTypes.XIST,
        item: { id: tree.id, parent_id: tree.parent_id, name: tree.name },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1
        })
    }), []);
    const [{ hover, name, canDrop }, dropRef] = useDrop(() => ({
        accept: DnDItemTypes.XIST,
        drop: (e: any) => {
            console.debug("dropping", e.id, "to", tree.id, e.name);
            onMoveNode(e.id, tree.id);
        },
        canDrop: (_item: any, _monitor: any) => tree.directory,
        collect: (monitor) => ({
            hover: monitor.isOver({ shallow: true }),
            name: monitor.getItem()?.name,
            canDrop: tree.directory
        })
    }), []);

    let textColor = "";
    switch (tree.status) {
        case 0:
            textColor = "text-primary";
            break;
        case 1:
            textColor = "text-warning";
            break;
        case 2:
            textColor = "text-muted";
            break;
        default:
            textColor = "text"
    }
    return (
        <div key={tree.id} className="list-group list-group-flush">
            <div ref={dropRef} style={{ backgroundColor: (hover && canDrop) ? 'lime' : '' }} className="d-flex justify-content-between align-items-center">
                <div className='d-flex' style={{ position: "relative" }}>
                    {tree.directory === true && tree.id !== 0 ?
                        <i onClick={toggleOpen} className="material-icons" style={{ position: "relative", left: "-8px" }}>{isOpen ? "chevron_right" : "expand_more"}</i>
                        : tree.id === 0 ? null : <span className='d-inline-block' style={{ width: '24px', minWidth: '24px' }}></span>
                    }
                    {(tree.id === 0) ? <NavLink to={`/project/${projectId}/xist`}>Elemente</NavLink> : <NavLink to={`/project/${projectId}/xist/${tree.id}`} ref={dragRef} style={{ opacity, position: "relative", left: "-8px" }} className={`d-inline-block text-truncate ${textColor}`}>{tree.name}</NavLink>}
                </div>
                {tree.directory === true &&
                    <span className="badge badge-primary badge-pill" style={{ background: 'lightblue' }}>{tree.children.length}</span>
                }
            </div>
            <div style={{ display: (hover && canDrop) ? 'block' : 'none', fontSize: '10px' }}>
                - {name}
            </div>
            {
                (!isOpen || tree.id === 0) && (
                    <>
                        <div className={flat === true ? undefined : tree.id === 0 ? undefined : 'ms-3'}>
                            {
                                tree.children.map((child: any) => {
                                    return (
                                        <Tree key={child.id} tree={child} projectId={projectId} onMoveNode={onMoveNode} redraw={redraw} />
                                    )
                                })
                            }
                        </div>
                    </>
                )
            }
        </div >
    )
}