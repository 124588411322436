import React, { useState } from 'react';

import { withKeyAndJsonHeaders } from '../../api';
//import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ProjectRoles } from '../roles/ProjectRoles';
import { Project } from '../../../protos/protos/projects';

interface ProjectSettingsProps {
    project: Project;
    isProjectLead?: boolean;
    readOnly: boolean;
}

export function ProjectSettings({ project, readOnly }: ProjectSettingsProps) {
    //const [t, i18n] = useTranslation();
    const [changed, setChanged] = useState<boolean>(false);
    const [project_name, setProjectName] = useState<string>(project.name);
    const [deletableProjectName, setDeletableProjectName] = useState<string>("");
    const [deletable, setDeletable] = useState<boolean>(false);
    const abortController = new AbortController();

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        fetch(`/api/v1/project/${project.id}`, withKeyAndJsonHeaders({
            method: 'PUT',
            signal: abortController.signal,
            body: JSON.stringify({ id: project.id, name: project_name })
        })).then(
            () => {
                setChanged(false);
            }
        );
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.value;
        //const name = target.name;
        setChanged(true);
        setProjectName(value);
    }


    function onChangeConfirmDeleteProject(e: React.ChangeEvent<HTMLInputElement>) {
        const target = e.target;
        const value = target.value;
        //const name = target.name;
        if (value === project.name) {
            setDeletable(true);
        } else {
            setDeletable(false);
        }
        setDeletableProjectName(value);
    }


    function onClickArchive(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        fetch(`/api/v1/project/${project.id}`, withKeyAndJsonHeaders({
            method: 'DELETE',
            signal: abortController.signal
        })).then(() => {
            alert('Projekt erfolgreich archiviert.');
        });
    }

    function onClickDelete(e: React.MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        fetch(`/api/v1/project/${project.id}/delete`, withKeyAndJsonHeaders({
            method: 'DELETE',
            signal: abortController.signal
        })).then(() => {
            alert('Projekt erfolgreich gelöscht.');
        });
    }

    return (
        <>
            <div className='row'>
                <div className="col py-3">
                    <ProjectRoles />
                </div>
            </div>
            {readOnly ? (
                <div className="col">
                    <div className="shadow-sm p-3">
                        archivier am, {moment(project.closed_date).format("DD.MM.YYYY HH:mm")})
                    </div>
                </div>
            ) : <>
                <div className="col py-3">
                    <h2>Projekt umbenennen</h2>
                    <div className="row">
                        <div className="col">
                            <form className="shadow-sm p-3 clearfix" onSubmit={onSubmit}>
                                <div className="form-group">
                                    <label htmlFor="projectName">Projektname:</label>
                                    <input className="form-control form-control-sm" id="projectName" type="text" name="project_name"
                                        value={project_name} onChange={onChange} />
                                </div>
                                {changed && <button type="submit" className="btn btn-outline-success float-end">Übernehmen</button>}
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-12 py-3">
                    <h2>Projekt archivieren</h2>
                    <div className="shadow-sm py-3">
                        <div className="text-end">
                            <a href="#" className="btn btn-danger" onClick={onClickArchive}>Projekt archivieren</a>
                        </div>
                    </div>
                </div>
            </>
            }
            <div className="col-12 py-3">
                <h2>Projekt löschen</h2>
                <div className="shadow-sm py-3">
                    <div className="form-group">
                        <label htmlFor="projectName">Projektname:</label>
                        <input className="form-control form-control-sm" id="projectName" type="text" name="project_name"
                            value={deletableProjectName} onChange={onChangeConfirmDeleteProject} />
                        <span className="text-center">Um das Projekt zu löschen bitte den Projektnamen zur Bestätigung angeben.</span>
                    </div>
                    <div className="text-end">
                        {deletable && <a href="#" className="btn btn-danger" onClick={onClickDelete}>Projekt löschen</a>}
                    </div>
                </div>
            </div>
        </>
    );
}